import { configureStore } from "@reduxjs/toolkit";
import wishlistSlice from "./Features/wishlistSlice";
import cartSlice from "./Features/cartSlice";
import subcategoryslice from "./Features/subcategoryslice";
import addressSlice from "./Features/addressSlice";

export const store = configureStore({
  reducer: {
    Wishlist: wishlistSlice,
    Cart: cartSlice,
    subcategory: subcategoryslice,
    Address: addressSlice,
  },
});
