import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Footer from "../../components/Footer/footer";
import AdminHeader from "../Adminheader/Adminheader";
import Tracker from "../../components/tracker/tracker";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const IthinkOrders = () => {
  const [data, setdata] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [show, setshow] = useState({});
  const [status, setstatus] = useState("");

  const fetchOrders = async () => {
     let url = process.env.REACT_APP_HOST + `order/getAllcompleteorders`;
     try {
       const response = await axios.get(url);
       // console.log(response.data)
       setdata(response.data);
     } catch (error) {
       console.error("Error fetching order:", error);
     }
  };  

  const givenDateString = "2024-04-10T13:43:54.806Z";

  const givenDate = new Date(givenDateString);

  const today = new Date();

  today.setHours(0, 0, 0, 0);

  const differenceInMilliseconds = givenDate - today;

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  //console.log(`The difference in days is: ${Math.round(differenceInDays)}`);

  const handle = (order) => {
    // console.log(order);
    setshow(order);
    setLgShow(!lgShow);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  if (!data.length) {
    return (
      <>
        <AdminHeader />
        <div className="empty-wishlist-container">
          <img
            src="/images/preview.png"
            alt="Empty Wishlist"
            className="wishlist-image"
          />
          <h2>Empty Oders</h2>
          <p>You haven't ordered anything yet ! start shopping now</p>
        </div>
      </>
    );
  }
  return (
    <>
      <AdminHeader />
      <Tabs
        defaultActiveKey="home"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="home" title="INTRANSIT">
          <div className="ord">
            <div className="orders-container">
              {data.map((order) => (
                <div
                  key={order._id}
                  className="order-cardd"
                  onClick={() => handle(order)}
                >
                  <div className="products-containe">
                    {order.products.map((product, index) => (
                      <div key={index} className="product-cardd">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imaged"
                        />
                        <div className="product-info">
                          <h3 className="product-title">{product.title}</h3>
                          <p className="product-description">
                            {product.description}
                          </p>

                          <p>Quantity: {product.quantity}</p>
                        </div>
                        <p className="discounted-price">
                          ${product.discountedPrice * product.quantity}{" "}
                          {/* <span className="original-price">${product.price}</span> */}
                        </p>
                        {/* {order.status === "Order Placed" ||
                    order.status === "Ordered" ? (
                      <p className="sta">Ordered</p>
                    ) : order.status === "Order Processing" ? (
                      <p className="sta">Processing</p>
                    ) : order.status === "Order Confirmed" ? (
                      <p className="sta">Confirmed</p>
                    ) : order.status === "Order Packed in Dispatch House" ||
                      order.status === "Shipped to Delivery Center" ||
                      order.status === "Arrived at Nearest Delivery Facility" ||
                      order.status === "Out for Delivery" ? (
                      <p className="sta">Intransit </p>
                    ) : order.status === "Order Delivered" ? (
                      <p className="sta">Delivered</p>
                    ) : (
                      <></>
                    )} */}
                        {/* <p>{order.status}</p> */}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  ORDER AND SHIPPING DETAILS
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {show._id ? (
                  <>
                    <div className="order-info">
                      <h2>Order ID: {show._id}</h2>
                      <p>
                        Date: {new Date(show.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <div>
                      <div className="add">
                        <div className="addinne">
                          <h3 className="add-title">
                            {show.shippingAddress.name}{" "}
                            {show.shippingAddress.mobileNumber}
                          </h3>
                        </div>
                        <p className="add-addrss" style={{ margin: "0px" }}>
                          {show.shippingAddress.address},
                          {show.shippingAddress.locality},{show.landmark}
                          {show.shippingAddress.city} ,{" "}
                          {show.shippingAddress.state}-
                          {show.shippingAddress.pincode}
                        </p>
                      </div>
                    </div>
                    <div className="products-containe">
                      {show &&
                        show.products.map((product, index) => (
                          <div key={index} className="product-cardd">
                            <img
                              src={product.images[0]}
                              alt={product.title}
                              className="product-imaged"
                            />
                            <div className="product-info">
                              <h3 className="product-title">{product.title}</h3>
                              <p className="product-description">
                                {product.description}
                              </p>

                              <p>Quantity: {product.quantity}</p>
                            </div>
                            <p className="discounted-price">
                              ${product.discountedPrice * product.quantity}{" "}
                            </p>
                          </div>
                        ))}
                      <Tracker status={show.status} waybill={show.waybill} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </Tab>
        <Tab eventKey="profile" title="CANCELLED">
          <div className="ord"></div>
        </Tab>
        <Tab eventKey="longer-tab" title="RETURNS">
         <div className="ord"></div>
        </Tab>
        {/* <Tab eventKey="contact" title="Contact" disabled>
          Tab content for Contact
        </Tab> */}
      </Tabs>

      <Footer />
    </>
  );
};

export default IthinkOrders;
