import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthState from "./context/Auth/AuthState";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { store } from "./Store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="sportsmart.us.auth0.com"
    clientId="uS8tzseOVPxnLwEv511SfLq8eealhPju"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <AuthState>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthState>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
