import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const Completeorder = () => {
  const [data, setdata] = useState();
  const [total, settotal] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [ithinkData, setithinkData] = useState({
    orderCompleted: false,
    waybill: "",
    refnum: "",
    trackingUrl: "",
  });

  const [formData, setFormData] = useState({
    waybill: "",
    order: "",
    sub_order: "",
    order_date: "",
    total_amount: 0.0,
    name: "",
    address: "",
    address2: "",
    pin: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    alt_phone: "",
    email: "",
    is_billing_same_as_shipping: "",
    product_name: "",
    product_quantity: 0,
    product_price: 0.0,
    shipment_length: "",
    shipment_width: "",
    shipment_height: "",
    weight: "",
    shipping_charges: "0",
    giftwrap_charges: "0",
    transaction_charges: "0",
    total_discount: "0",
    first_attempt_discount: "0",
    reseller_name: "",
    eway_bill_number: "",
    gst_number: "",
    cod_amount: 0.0,
    payment_mode: "",
    return_address_id: "",
    store_id: "",
    pickup_address_id: "",
    access_token: "",
    secret_key: "",
    logistics: "",
    s_type: "",
  });

  const [orderData, setorderData] = useState({
    data: {
      shipments: [
        {
          waybill: "",
          order: "",
          sub_order: "",
          order_date: "",
          total_amount: "",
          name: "",
          add: "",
          add2: "",
          pin: "",
          city: "",
          state: "",
          country: "India",
          phone: "",
          alt_phone: "",
          email: "",
          is_billing_same_as_shipping: "Yes",
          products: [
            {
              product_name: "",
              product_quantity: "",
              product_price: "",
            },
          ],
          shipment_length: "",
          shipment_width: "",
          shipment_height: "",
          weight: "",
          shipping_charges: 0,
          giftwrap_charges: 0,
          transaction_charges: 0,
          total_discount: 0,
          first_attemp_discount: 0,
          reseller_name: "",
          eway_bill_number: "",
          gst_number: "",
          cod_amount: 0,
          payment_mode: "COD",
          return_address_id: "2434",
          store_id: "16458",
        },
      ],
      pickup_address_id: "2434",
      access_token: "5a7b40197cd919337501dd6e9a3aad9a",
      secret_key: "2b54c373427be180d1899400eeb21aab",
      logistics: "Delhivery",
      s_type: "ground",
    },
  });

  const navigate = useNavigate()

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  let totalamount = 0;

  const handleSubmit = (event) => {
    event.preventDefault();

    let Items = [];

    for (let i = 0; i < data.products.length; i++) {
      totalamount =
        totalamount +
        parseInt(data.products[i].discountedPrice) *
          parseInt(data.products[i].quantity);

      let item = {
        product_name: data.products[i].title,
        product_quantity: data.products[i].quantity,
        product_price: data.products[i].discountedPrice,
      };

      Items.push(item);
    }

    let COD =
      parseInt(totalamount) +
      parseInt(formData.shipping_charges) +
      parseInt(formData.giftwrap_charges) +
      parseInt(formData.transaction_charges);

    setorderData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        shipments: [
          {
            ...prevState.data.shipments[0],
            total_amount: totalamount,
            name: data.shippingAddress.name,
            add: `${data.shippingAddress.address} `,
            pin: data.shippingAddress.pincode,
            city: data.shippingAddress.city,
            state: data.shippingAddress.state,
            phone: data.shippingAddress.mobileNumber,
            alt_phone: data.shippingAddress.mobileNumber,
            email: data.shippingAddress.email || "me@gmail.com",
            shipment_length: formData.shipment_length,
            shipment_width: formData.shipment_width,
            shipment_height: formData.shipment_height,
            weight: formData.weight,
            shipping_charges: formData.shipping_charges,
            giftwrap_charges: formData.giftwrap_charges,
            transaction_charges: formData.transaction_charges,
            waybill: formData.waybill,
            cod_amount: COD,
            products: Items,
            order: formData.order,
            sub_order: formData.sub_order,
            order_date: new Date(),
          },
        ],
      },
    }));
    setLgShow(!lgShow);
  };

  const getOrder = async () => {
    let id = localStorage.getItem("completeorderid");

    let url = process.env.REACT_APP_HOST + `order/getorder/${id}`;
    try {
      const response = await axios.get(url);
      //console.log(response.data);
      setdata(response.data);
      for (let i = 0; i < response.data.products.length; i++) {
        totalamount =
          totalamount +
          parseInt(response.data.products[i].discountedPrice) *
            parseInt(response.data.products[i].quantity);
      }
      settotal(totalamount);
    } catch (error) {
      //console.error("Error getting order:", error.message);
    }
  };

  const completeRequest = () => {
    //console.log(orderData.data);
    // Return a new promise
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://pre-alpha.ithinklogistics.com/api_v3/order/add.json",
          {
            data: orderData.data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "success") {
            setithinkData({
              ...ithinkData,
              orderCompleted: true,
              waybill: response.data.data[1].waybill,
              refnum: response.data.data[1].refnum,
              trackingUrl: response.data.data[1].waybil || "",
            });

            let id = localStorage.getItem("completeorderid");
            let url = process.env.REACT_APP_HOST + `order/updateOrder/${id}`;
            return axios.put(url, {
              orderCompleted: true,
              waybill: response.data.data[1].waybill,
              refnum: response.data.data[1].refnum,
              trackingUrl: response.data.data[1].waybil || "",
            });
          } else {
            console.error("Order addition failed:", response.data.html_message);
            alert(`Order addition failed: ${response.data}`);
          }
        })
        .then((response) => {
          alert("Order updated successfully!");
          console.log(response.data);
          navigate("/adminorders");
          // resolve(response.data);
          setLgShow(!lgShow);
        })
        .catch((error) => {
          console.error("Error occurred:", error.message);
          alert(`An error occurred: ${error.message}`);
        });
    });
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <>
      <div className="CompleteOrder__container">
        <h2>Add Shipment Details</h2>
        <form onSubmit={handleSubmit} className="CompleteOrder__form">
          {parseInt(total) >= 50000 ? (
            <div className="CompleteOrder__form-group">
              <label htmlFor="order" className="CompleteOrder__label">
                Way-Bill Number:
              </label>
              <input
                className="CompleteOrder__input"
                type="text"
                name="order"
                id="order"
                value={formData.waybill}
                onChange={handleChange}
                placeholder="Waybill Number"
              />
            </div>
          ) : (
            <></>
          )}
          <div className="CompleteOrder__form-group">
            <label htmlFor="order" className="CompleteOrder__label">
              Order ID:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="order"
              id="order"
              value={formData.order}
              onChange={handleChange}
              placeholder="Order ID"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="sub_order" className="CompleteOrder__label">
              Sub Order ID:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="sub_order"
              id="sub_order"
              value={formData.sub_order}
              onChange={handleChange}
              placeholder="Sub Order ID"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="shipment_length" className="CompleteOrder__label">
              Shipment Length:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="shipment_length"
              id="shipment_length"
              value={formData.shipment_length}
              onChange={handleChange}
              placeholder="Shipment Length"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="shipment_width" className="CompleteOrder__label">
              Shipment Width:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="shipment_width"
              id="shipment_width"
              value={formData.shipment_width}
              onChange={handleChange}
              placeholder="Shipment Width"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="shipment_height" className="CompleteOrder__label">
              Shipment Height:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="shipment_height"
              id="shipment_height"
              value={formData.shipment_height}
              onChange={handleChange}
              placeholder="Shipment Height"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="weight" className="CompleteOrder__label">
              Weight:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="weight"
              id="weight"
              value={formData.weight}
              onChange={handleChange}
              placeholder="Weight"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="shipping_charges" className="CompleteOrder__label">
              Shipping Charges:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="shipping_charges"
              id="shipping_charges"
              value={formData.shipping_charges}
              onChange={handleChange}
              placeholder="Shipping Charges"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label htmlFor="giftwrap_charges" className="CompleteOrder__label">
              Giftwrap Charges:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="giftwrap_charges"
              id="giftwrap_charges"
              value={formData.giftwrap_charges}
              onChange={handleChange}
              placeholder="Giftwrap Charges"
            />
          </div>

          <div className="CompleteOrder__form-group">
            <label
              htmlFor="transaction_charges"
              className="CompleteOrder__label"
            >
              Transaction Charges:
            </label>
            <input
              className="CompleteOrder__input"
              type="text"
              name="transaction_charges"
              id="transaction_charges"
              value={formData.transaction_charges}
              onChange={handleChange}
              placeholder="Transaction Charges"
            />
          </div>

          <button className="CompleteOrder__button" type="submit">
            Submit
          </button>
        </form>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            ORDER DETAILS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="submitted-data-container">
            <div className="data-row">
              <label>Order ID:</label>
              <span>{formData.order}</span>
            </div>
            <div className="data-row">
              <label>Sub Order ID:</label>
              <span>{formData.sub_order}</span>
            </div>
            <div className="data-row">
              <label>Shipment Length:</label>
              <span>{formData.shipment_length}</span>
            </div>
            <div className="data-row">
              <label>Shipment Width:</label>
              <span>{formData.shipment_width}</span>
            </div>
            <div className="data-row">
              <label>Shipment Height:</label>
              <span>{formData.shipment_height}</span>
            </div>
            <div className="data-row">
              <label>Weight:</label>
              <span>{formData.weight}</span>
            </div>
            <div className="data-row">
              <label>Shipping Charges:</label>
              <span>{formData.shipping_charges}</span>
            </div>
            <div className="data-row">
              <label>Giftwrap Charges:</label>
              <span>{formData.giftwrap_charges}</span>
            </div>
            <div className="data-row">
              <label>Transaction Charges:</label>
              <span>{formData.transaction_charges}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setLgShow(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              completeRequest();
            }}
          >
            Complete Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Completeorder;
