import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./footer.css";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-main">
            <div className="rows">
              <div className="foot-1">
                <div className="footer-widget f-w4 mb-40">
                  <h3>Contact Us</h3>
                  <ul>
                    <li>
                      <h5
                        style={{
                          marginTop: "30px",
                          color: "rgb(108, 107, 107)",
                          fontSize: "17px",
                        }}
                      >
                        Sportsmart@gmail.com
                      </h5>
                    </li>
                    <li>
                      <h5
                        style={{
                          marginTop: "20px",
                          color: "rgb(108, 107, 107)",
                          fontSize: "16px",
                        }}
                      >
                        1234567890
                      </h5>
                    </li>
                    <li>
                      <button
                        style={{
                          marginTop: "15px",
                          color: "rgb(108, 107, 107)",
                          fontSize: "17px",
                        }}
                        onClick={() => {
                          navigate("/Admin");
                        }}
                      >
                        India
                      </button>
                    </li>
                    <div className="a">
                      <a
                        href="https://www.facebook.com/sportsmartonlinestore/"
                        target="_blank"
                        rel="noreferrer"
                        className="smicons"
                      >
                        <FaFacebook />
                      </a>
                      <a
                        href="https://www.youtube.com/@SportsMart.online"
                        target="_blank"
                        rel="noreferrer"
                        className="smicons"
                      >
                        <FaYoutube />
                      </a>
                      <a
                        href="https://www.instagram.com/sportsmart_online?igsh=MWNzYTZleWt2bzE0Zg=="
                        target="_blank"
                        rel="noreferrer"
                        className="smicons"
                      >
                        <RiInstagramFill />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/98794947/admin/dashboard/"
                        target="_blank"
                        rel="noreferrer"
                        className="smicons"
                      >
                        <FaLinkedin />
                      </a>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="foot-1">
                <div className="footer-widget f-w3">
                  <h3>Useful Links</h3>
                  <ul>
                    <li>
                      <Link to="/cart">Profile</Link>
                    </li>
                    <li>
                      <Link to="/orders">Orders</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li>
                      <Link to="/">Products</Link>
                    </li>
                    <li>
                      <Link to="/cart">Bag</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="foot-1">
                <div className="footer-widget f-w2 mb-40">
                  <h3>Categories</h3>
                  <ul style={{ color: "rgb(108, 107, 107)" }}>
                    <li>Cricket</li>
                    <li>Football</li>
                    <li>Badminton</li>
                    <li>Tennis</li>
                    <li>Hockey</li>
                  </ul>
                </div>
              </div>
              <div className="foot-1" id="foot1">
                <div className="footer-widget f-w1 mb-40">
                  <div className="footer-img">
                    <Link to="/">
                      <img
                        src="/images/ss.jpg"
                        alt="footer-logo"
                        id="img1"
                        style={{ width: "200px", height: "80px" }}
                      />
                    </Link>
                    <p style={{ color: "rgb(108, 107, 107)" }}>
                      At Sportsmart, we're passionate about sports and fitness.
                      Our mission is to provide top-quality sporting goods and
                      equipment to athletes of all levels, helping them achieve
                      their goals and perform at their best.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ color: "rgb(108, 107, 107)" }} />
            <h5 style={{ color: "rgb(108, 107, 107)" }}>
              {" "}
              @Sportmart , we love our users
            </h5>
          </div>
          <div className="copyright-area"></div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
