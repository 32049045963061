import React, { useState, useEffect } from "react";
import axios from "axios";
import "./review.css";
import { useNavigate } from "react-router-dom";

const ReviewForm = ({ productId }) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState([]);
  const [error, setError] = useState("");
 const [isEligible, setIsEligible] = useState(true); // Eligibility state
  const UserID = sessionStorage.getItem("User_Id");
  const navigate = useNavigate();

  // useEffect(() => {
  //   checkEligibility(); // Check eligibility when component mounts
  // }, []);

  // const checkEligibility = async () => {
  //   const ProductID = localStorage.getItem("product");

  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_HOST}product/reviews/eligibility/${ProductID}/${UserID}`
  //     );

  //     if (response.status === 200) {
  //       setIsEligible(true); // Eligible to review
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       setIsEligible(false); // Not eligible to review
  //     }
  //   }
  // };

  const removeImage = (index) => {
    const updatedImages = [...image];
    updatedImages.splice(index, 1);
    setImage([...updatedImages]);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const imageFiles = selectedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (imageFiles.length < selectedFiles.length) {
      setError("Only images (JPEG, PNG, GIF) are allowed.");
    } else {
      setError("");
    }

    if (imageFiles.length > 0) {
      setImage([...image, ...imageFiles]);
    }
  };

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const handleRatingHover = (value) => {
    setHoverRating(value);
  };

  const handleRatingLeave = () => {
    setHoverRating(0);
  };

  const submitReview = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("title", title);
    formData.append("comment", comment);
    formData.append("UserID", UserID);    

    if (image && image.length > 0) {
      image.forEach((file) => formData.append("images", file));
    }

    const ProductID = localStorage.getItem("product");

    try {
      const url = `${process.env.REACT_APP_HOST}product/reviews/${ProductID}`;
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        alert("Review submitted successfully!");
        navigate(`/product/${ProductID}`);
      } else {
        alert(`Failed to submit review: ${response.data.message}`);
      }
    } catch (error) {
      console.log(error);
      
      // if (error.response) {
      //   alert(`Error submitting review: ${error.response.data.message}`);
      // } else if (error.request) {
      //   alert("No response received from the server.");
      // } else {
      //   alert(`Error submitting review: ${error.message}`);
      // }
    }
  };

  return (
    <div style={{ minHeight: "40vh" }}>
      <form className="review-form" onSubmit={submitReview}>
        <h2 className="form-title">Write a Review</h2>
        {!isEligible ? (
          <div className="alert-box">
            <span className="alert-message">
              You are not eligible to review this product, Please contact to our
              support team for any further assistance.
            </span>
          </div>
        ) : (
          <>
            <div className="form-group">
              <label className="form-label">Rating</label>
              <div className="rating-stars">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={`star ${
                      star <= (hoverRating || rating) ? "filled" : ""
                    }`}
                    onClick={() => handleRatingClick(star)}
                    onMouseEnter={() => handleRatingHover(star)}
                    onMouseLeave={handleRatingLeave}
                  >
                    ★
                  </span>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">Title</label>
              <input
                className="form-input"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                placeholder="Enter a title for your review. "
              />
            </div>
            <div className="form-group">
              <label className="form-label">Review</label>
              <textarea
                className="form-textarea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
                placeholder="Share your experience with this product"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Upload an Image</label>
              <div className="image-preview-container">
                {image.length > 0 &&
                  image.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        className="image-thumbnail"
                      />
                      <button
                        type="button"
                        className="remove-image-btn"
                        onClick={() => removeImage(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
              </div>
              <input
                className="form-input-file"
                type="file"
                multiple
                onChange={handleFileChange}
              />
            </div>
            <span style={{color:"red"}}>{error}</span>
            <button className="form-submit-btnn" type="submit">
              Submit Review
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ReviewForm;
