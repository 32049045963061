import { useState } from "react";
import AuthContext from "./AuthContext";

const AuthState = ({ children }) => {
  const sub = localStorage.getItem("sub");
  const [auth, setAuth] = useState(sub);
  const [Cartitems, setCartitems] = useState([]);
  const [modalitem, setmodalitem] = useState("hiii");
  const [vendor_id, setvendor_id] = useState("");
  const [search, setsearch] = useState("");

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        Cartitems,
        setCartitems,
        modalitem,
        setmodalitem,
        vendor_id,
        setvendor_id,
        search,
        setsearch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthState;
