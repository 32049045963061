import React from 'react'
import { Link } from "react-router-dom";
import "./page.css"

const PageNotfound = () => {
  return (
    <div className="not-found-container">
      <h1>404</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="back-home">
        Go back to Home
      </Link>
    </div>
  );
}

export default PageNotfound
