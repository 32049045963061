import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import Footer from "../../components/Footer/footer";

const sportsCategories = {
  CRICKET: [
    "Cricket Bats",
    "Batting Gloves",
    "Batting Pads",
    "Cricket Bags",
    "Cricket Helmets",
    "Cricket Protective",
    "Wicket Keeping",
    "Cricket Balls",
    "Cricket Accessories",
    "Cricket Shoes",
    "Cricket Clothing",
    "Cricket Supporter Wear",
    "Cricket Others",
  ],
  FOOTBALL: [
    "Football Boots",
    "Football Jerseys",
    "Football Shorts",
    "Football Socks",
    "Goalkeeper Gloves",
    "Football Balls",
    "Training Equipment",
    "Football Accessories",
    "Football Others",
  ],
  BADMINTON: [
    "Badminton Racquets",
    "Badminton Accessories",
    "Squash Racquets",
    "Squash Accessories",
    "Court Shoes",
    "Badminton Others",
  ],
  TENNIS: [
    "Adult Tennis Racquets",
    "Junior Tennis Racquets",
    "Tennis Balls",
    "Tennis Bags",
    "Tennis Accessories",
    "Tennis Clothing",
    "Tennis Shoes",
    "Pickleball",
    "Tennis Others",
  ],
  HOCKEY: [
    "Hockey Sticks",
    "Hockey Balls",
    "Hockey Bags",
    "Hockey Protective & Accessories",
    "Hockey Shoes",
    "Hockey Others",
  ],
  OTHERSPORTS: [
    "Squash Rackets",
    "Boxing, Karate, Wrestling",
    "Chess",
    "Swimming",
    "Pickleball Paddle",
    "Others",
  ],
};

const colorOptions = ["Red", "Blue", "Black", "White", "Green", "Others"];

function AddVendorProduct() {
  const [product, setProduct] = useState({
    title: "",
    description: "",
    price: 0,
    discountPercentage: 0,
    discountedPrice: 0,
    brand: "",
    category: "",
    subcategory: "",
    color: "",
    vendorID: localStorage.getItem("vendorID"),
  });
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const handleInputChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in product) {
      formData.append(key, product[key]);
    }
    for (const file of files) {
      formData.append("images", file);
    }

    try {
      let url = process.env.REACT_APP_HOST + "product/addProduct";
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Product added successfully!");
      setProduct({
        title: "",
        description: "",
        price: 0,
        discountPercentage: 0,
        discountedPrice: 0,
        brand: "",
        category: "",
        subcategory: "",
        color: "",
        vendorID: localStorage.getItem("vendorID"),
      });
      navigate("/vendorProfile");
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Error adding product.");
    }
  };

  return (
    <>
      <div className="back">
        <div className="vendor-form-wrapper">
          <h2 className="vendor-form-title" style={{ margin: "15px" }}>
            ADD NEW PRODUCT
          </h2>
          <Form onSubmit={handleSubmit} className="vendor-form-content">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={product.title}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  name="description"
                  value={product.description}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPrice">
                <Form.Label>Original Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter price"
                  name="price"
                  value={product.price}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDiscountPercentage">
                <Form.Label>Discount Percentage</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter discount percentage"
                  name="discountPercentage"
                  value={product.discountPercentage}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDiscountedPrice">
                <Form.Label>Discounted Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter discounted price"
                  name="discountedPrice"
                  value={product.discountedPrice}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridBrand">
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter brand"
                  name="brand"
                  value={product.brand}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridColor">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  as="select"
                  name="color"
                  value={product.color}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select</option>
                  {colorOptions.map((color) => (
                    <option key={color} value={color}>
                      {color}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={product.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select</option>
                  {Object.keys(sportsCategories).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {product.category && (
                <Form.Group as={Col} controlId="formGridSubcategory">
                  <Form.Label>Subcategory</Form.Label>
                  <Form.Control
                    as="select"
                    name="subcategory"
                    value={product.subcategory}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select</option>
                    {sportsCategories[product.category].map((subcategory) => (
                      <option key={subcategory} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridImages">
                <Form.Label>Images</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Row>

            <div className="btn-wrapper">
              <Button
                variant="primary"
                type="submit"
                style={{ width: "200px", margin: "20px" }}
              >
                Add Product
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddVendorProduct;
