import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import ReactPaginate from "react-paginate";
import axios from "axios";
import "./Admin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AdminHeader from "../Adminheader/Adminheader";
import { useNavigate } from "react-router-dom";

const Adminproduct = () => {
  const [pageCount, setpageCount] = useState(1);
  const [data, setdata] = useState([]);
   const [limit, setlimit] = useState(0);
  const [lgShow, setLgShow] = useState(false);
  const [editId, seteditId] = useState("");
  const [editproduct, seteditProduct] = useState({
    title: "",
    description: "",
    price: 0,
    discountPercentage: 0,
    discountedPrice: 0,
    brand: "",
    category: "",
  });
  const navigate = useNavigate()

  const handleShow = (item) => {
    //console.log(item);
    seteditId(item._id);
    seteditProduct({ ...item });
    setLgShow(true);
  };

  const handlePageClick = async (data) => {
     let currentPage = data.selected + 1;
     setpageCount(currentPage);
    // window.scrollTo(0, 0)
    // fetchProducts()
  };

  const fetchProducts = async () => {
    let id = localStorage.getItem("sub");
    console.log(id);
    let url =
      process.env.REACT_APP_HOST +
      `product/getproducts?page=${pageCount}&id=${id}`;
    try {
      const response = await axios.get(url, id);
      setlimit(response.data.totalPages); // Set the total number of pages
      setpageCount(response.data.currentPage);
      // console.log(response.data);
      setdata(response.data.products);
    } catch (error) {
      // console.error("Failed to fetch products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [pageCount]);

  const handleupdate = async (e) => {
    e.preventDefault();
    setLgShow(false);
    try {
      let url = process.env.REACT_APP_HOST + `product/updateproduct/${editId}`;
      const response = await axios.patch(url, editproduct);
      alert("product edited successfully!");
    } catch (error) {
      console.error(error.message);
      //   alert("Error Editing address.");
    }
  };

  const handledelete = async (id) => {
    // console.log(id);
    const list = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id !== id) {
        list.push(data[i]);
      }
    }
    setdata([...list]);
    try {
      let url = process.env.REACT_APP_HOST + `product/deleteproduct/${id}`;
      const response = await axios.delete(url);
      alert("product deleted successfully!");
    } catch (error) {
      console.error(error.message);
      alert("Error deleting product.");
    }
  };


  return (
    <div>
      <AdminHeader />
      {/* <button
        className="register-button"
        onClick={() => navigate("/Addproduct")}
      >
        ADD PRODUCT
      </button> */}
      {data &&
        data.map((item, index) => {
          return (
            <div key={index}>
              <div className="Admin-card">
                <div>
                  <div className="Admin-image">
                    <img src={item.images[0]} alt="...loading" />
                  </div>
                </div>
                <div className="Admin-details">
                  <h3>{item.title}</h3>
                  <p className="Admin-brand">{item.brand}</p>
                  <p className="Admin-description">{item.description}</p>
                  <p className="Admin-pric">
                    <span className="Admin-discounted-price">
                      &#8377;{item.discountedPrice}
                    </span>
                    <span className="Admin-original-price">{item.price}</span>
                    <span className="Admin-discount">
                      {item.discountPercentage}% OFF
                    </span>
                  </p>
                </div>
                <button className="Admin-edit" onClick={() => handleShow(item)}>
                  EDIT
                </button>
                <button
                  className="Admin-del"
                  onClick={() => handledelete(item._id)}
                >
                  DELETE
                </button>
              </div>
            </div>
          );
        })}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">EDIT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="addTitle">Title</label>
          <input
            type="text"
            name="title"
            value={editproduct.title}
            className="in"
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                title: e.target.value,
              })
            }
          />
          <label className="addTitle">Description</label>
          <textarea
            className="in"
            name="description"
            value={editproduct.description}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                description: e.target.value,
              })
            }
          ></textarea>
          <label className="addTitle">Price</label>
          <input
            type="number"
            className="in"
            name="price"
            value={editproduct.price}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                price: e.target.value,
              })
            }
          />
          <label className="addTitle">Discount Percentage</label>
          <input
            type="number"
            className="in"
            name="discountPercentage"
            value={editproduct.discountPercentage}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                discountPercentage: e.target.value,
              })
            }
          />
          <label className="addTitle">Discounted Price</label>
          <input
            type="number"
            className="in"
            name="discountedPrice"
            value={editproduct.discountedPrice}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                discountedPrice: e.target.value,
              })
            }
          />
          <label className="addTitle">Brand</label>
          <input
            type="text"
            className="in"
            name="brand"
            value={editproduct.brand}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                brand: e.target.value,
              })
            }
          />
          {/* <label className="addTitle">Category</label>
          <input
            type="text"
            name="category"
            value={editproduct.category}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                category: e.target.value,
              })
            }
          /> */}
          <div>
            <Button variant="primary" onClick={handleupdate}>
              Update
            </Button>{" "}
            <Button variant="dark" onClick={() => setLgShow(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={limit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
      <Footer />
    </div>
  );
};

export default Adminproduct;
