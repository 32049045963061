import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import toast from "react-hot-toast";
import { Form, Row, Col, Button } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function VendorForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    storeName: "",
    email: "",
    area: "",
    city: "",
    state: "",
    pincode: "",
    password: "",
    landmark: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (e) => {
    handleChange(e);
    if (
      e.target.name === "confirmPassword" &&
      e.target.value !== formData.password
    ) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.loading("...Loading");
    let url = process.env.REACT_APP_HOST + "vendor/addvendor";
    //console.log(formData);
    try {
      const response = await axios.post(url, formData);
      if (response) {
        toast.dismiss();
        toast.success("Vendor registered successfully!");
      }
      navigate("/VendorLogin");
    } catch (error) {
      toast.dismiss();
      //console.error("Error registering vendor", error);
      alert("Failed to register vendor.")
    }
  };

  return (
    <>
      <div className="back">
        <div className="vendor-form-wrapper">
          <h2 className="vendor-form-title" style={{ margin: "15px" }}>
            SIGN UP NOW
          </h2>
          <Form onSubmit={handleSubmit} className="vendor-form-content">
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridStoreName">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter store name"
                  name="storeName"
                  value={formData.storeName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              {" "}
              <Form.Group as={Col} controlId="formGridArea">
                <Form.Label>Area</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter area"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridArea">
                <Form.Label>Lankmark</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Landmark"
                  name="landmark"
                  value={formData.landmark}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Password</Form.Label>
                <div className="password-wrapper">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    className="eye-button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <div className="password-wrapper">
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handlePasswordChange}
                    required
                  />
                  <button
                    type="button"
                    className="eye-button"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {passwordError && <div className="error">{passwordError}</div>}
              </Form.Group>
            </Row>
            <div className="btn-wrapper">
              <Button
                variant="primary"
                type="submit"
                style={{ width: "200px", margin: "20px" }}
              >
                Register
              </Button>

              <h5>
                Already have an Account?{" "}
                <span
                  className="sss"
                  onClick={() => {
                    navigate("/VendorLogin");
                  }}
                >
                  Log In
                </span>
              </h5>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VendorForm;
