import React from "react";
import { Link } from "react-router-dom";

const AdminHeader = () => {
  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/" className="title">
            <img src="/images/ss.jpg" alt="...loading" id="log" />
          </Link>
        </div>{" "}
        <ul className="nav-links" style={{ marginBottom: "0px" }}>
          <li>
            <Link to="/admin">DASHBOARD</Link>
          </li>
          <li>
            <Link to="/adminproduct">INVENTORY</Link>
          </li>
          <li>
            <Link to="/adminorders">ORDERS</Link>
          </li>
          <li>
            <Link to="/IthinkOrders">ITHINK</Link>
          </li>
          <li>
            <Link to="/vendor">VENDOR</Link>
          </li>
          <li>
            <Link to="/Addproduct">ADD PRODUCT</Link>
          </li>
        </ul>
        <div className="burger">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </>
  );
};

export default AdminHeader;
