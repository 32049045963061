import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthContext from "../../context/Auth/AuthContext";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { BiSort } from "react-icons/bi";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import { TiTick } from "react-icons/ti";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaArrowDownWideShort, FaArrowUpWideShort } from "react-icons/fa6";
import Card from "../Card/Card";
import Spinner from "../Spinner/spinner";

const categories = [
  "Cricket",
  "Football",
  "Badminton",
  "Tennis",
  "Hockey",
  "Indoor games",
];

const priceRanges = [
  "Rs. 0 to Rs. 500",
  "Rs. 500 to Rs. 1000",
  "Rs. 1000 to Rs. 5000",
  "Rs. 5000 to Rs. 10000",
  "Rs. 10000 to Rs. 50000",
];

const brand = ["Nike", "Adidas", "Puma", "Reebok", "Asics", "Others"];

const color = ["Red", "Blue", "Black", "White", "Green"];

const Home = () => {
  const [data, setdata] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [limit, setlimit] = useState(0);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [canvashow, setcanvaShow] = useState(false);
  const [activeFilter, setActiveFilter] = useState("categories");
  const [filterItems, setFilterItems] = useState(categories);
  const [sortOrder, setSortOrder] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({
    categories: [],
    priceRange: [],
    brand: [],
    color: [],
  });

  const Global = useContext(AuthContext);

  useEffect(() => {
    sendFiltersToBackend();
  }, [selectedFilters, sortOrder]);

  // Handle Category Change
  const handleCategoryChange = useCallback((category) => {
    setSelectedFilters((prev) => {
      const updatedCategories = prev.categories.includes(category)
        ? prev.categories.filter((cat) => cat !== category)
        : [...prev.categories, category];
      return { ...prev, categories: updatedCategories };
    });
  });

  // Handle Price Range Change
  const handlePriceRangeChange = useCallback((range) => {
    setSelectedFilters((prev) => {
      const updatedPrices = prev.priceRange.includes(range)
        ? prev.priceRange.filter((price) => price !== range)
        : [...prev.priceRange, range];
      return { ...prev, priceRange: updatedPrices };
    });
  });

  // Handle Brand Change
  const handleBrandChange = useCallback((brand) => {
    setSelectedFilters((prev) => {
      const updatedBrands = prev.brand.includes(brand)
        ? prev.brand.filter((item) => item !== brand)
        : [...prev.brand, brand];
      return { ...prev, brand: updatedBrands };
    });
  });

  // Handle Color Change
  const handleColorChange = useCallback((colored) => {
    setSelectedFilters((prev) => {
      const updatedColors = prev.color.includes(colored)
        ? prev.color.filter((c) => c !== colored)
        : [...prev.color, colored];
      return { ...prev, color: updatedColors };
    });
  });

  // Fetch filtered products from the backend
  const sendFiltersToBackend = useCallback(async () => {
    let url = process.env.REACT_APP_HOST + "product/filter";
    let params = { ...selectedFilters };

    // Adding sorting to the params
    if (sortOrder) {
      params.sort = sortOrder;
    }

    try {
      const response = await axios.get(url, { params });
      setdata(response.data);
    } catch (error) {
      console.error("Failed to fetch products", error);
    }
  });

  // Fetch Products without filters

  const fetchProducts = async () => {
    let id = localStorage.getItem("sub");
    let url =
      process.env.REACT_APP_HOST +
      `product/getproducts?page=${pageCount}&id=${id}`;
    try {
      const response = await axios.get(url, id);
      setlimit(response.data.totalPages);
      setpageCount(response.data.currentPage);
      setdata(response.data.products);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [pageCount]);

  const handleFilterChange = (filterType) => {
    setActiveFilter(filterType);
    switch (filterType) {
      case "categories":
        setFilterItems(categories);
        break;
      case "priceRanges":
        setFilterItems(priceRanges);
        break;
      case "brands":
        setFilterItems(brand);
        break;
      case "colors":
        setFilterItems(color);
        break;
      default:
        setFilterItems([]);
    }
  };

  const handlePageClick = async (data) => {
    
    let currentPage = data.selected + 1;
    localStorage.setItem("page", currentPage);
    setpageCount(currentPage);
  };

  const handleSearch = async () => {
    if (Global.search.length === 0) {
      return;
    }
    let url =
      process.env.REACT_APP_HOST + `product/search?query=${Global.search}`;
    try {
      const response = await axios.get(url);
      setdata(response.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [Global.search]);

  const handleFilterApply = () => {
    setShow(false);
    sendFiltersToBackend();
  };

  const handleFilterSelection = (item) => {
    switch (activeFilter) {
      case "categories":
        handleCategoryChange(item);
        break;
      case "priceRanges":
        handlePriceRangeChange(item);
        break;
      case "brands":
        handleBrandChange(item);
        break;
      case "colors":
        handleColorChange(item);
        break;
      default:
        break;
    }
  };

  const handleFilterClick = () => {
    setShow(!show);
  };

  const handleSortClick = () => {
    setcanvaShow(!canvashow);
  };

  const handleSortOrder = (order) => {
    if (order === "priceAsc") {
      const sortedData = [...data].sort(
        (a, b) => a.discountedPrice - b.discountedPrice
      );
      setdata(sortedData);
    } else {
      const sortedData = [...data].sort(
        (a, b) => b.discountedPrice - a.discountedPrice
      );
      setdata(sortedData);
    }
    setcanvaShow(false); // Close the sort canvas after selection
  };

  return (
    <>
      <Spinner />
      <div className="main-mid">
        <div className="filter">
          <h5 className="filterhead">FILTERS</h5>
          <div className="filter-container">
            <div className="filter-section">
              <p className="fil">CATEGORIES</p>
              {categories.map((category) => (
                <label key={category} className="checkbox-container">
                  {category}
                  <input
                    type="checkbox"
                    checked={selectedFilters.categories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                  />
                  <span className="checkmarkk"></span>
                </label>
              ))}
            </div>
            <div className="filter-section">
              <p className="fil">PRICE</p>
              {priceRanges.map((range) => (
                <label key={range} className="checkbox-container">
                  {range}
                  <input
                    type="checkbox"
                    checked={selectedFilters.priceRange.includes(range)}
                    onChange={() => handlePriceRangeChange(range)}
                  />
                  <span className="checkmarkk"></span>
                </label>
              ))}
            </div>
            <div className="filter-section">
              <p className="fil">BRAND</p>
              {brand.map((b) => (
                <label key={b} className="checkbox-container">
                  {b}
                  <input
                    type="checkbox"
                    checked={selectedFilters.brand.includes(b)}
                    onChange={() => handleBrandChange(b)}
                  />
                  <span className="checkmarkk"></span>
                </label>
              ))}
            </div>
            <div className="filter-section">
              <p className="fil">COLOR</p>
              {color.map((c) => (
                <label key={c} className="checkbox-container">
                  <div className="circlefilter" style={{ backgroundColor: c }}>
                    <span style={{ marginLeft: "25px", marginBottom: "10px" }}>
                      {c}
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    checked={selectedFilters.color.includes(c)}
                    onChange={() => handleColorChange(c)}
                  />
                  <span className="checkmarkk"></span>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div>
          <>
            <div id="home" key={pageCount}>
              {data &&
                data.map((item, index) => (
                  <div key={item._id}>
                    <Card item={item}></Card>
                  </div>
                ))}
            </div>
          </>
        </div>
      </div>

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={limit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />

      <div className="fixed-bottom-bar">
        <div className="sort-filter-container">
          <div className="sort" onClick={handleSortClick}>
            <BiSort style={{ fontSize: "18px", marginRight: "5px" }} />
            SORT
          </div>

          <div className="sfilter" onClick={handleFilterClick}>
            <TbAdjustmentsHorizontal
              style={{ fontSize: "18px", marginRight: "5px" }}
            />
            FILTER
          </div>
        </div>
      </div>

      {/* Mobile Filter Modal */}
      <Modal show={show} fullscreen={fullscreen}>
        <Modal.Body style={{ padding: "0px" }}>
          <div className="mobile-header">
            <h5 className="topfil">FILTERS</h5>
            <h5
              onClick={() =>
                setSelectedFilters({
                  categories: [],
                  priceRange: [],
                  brand: [],
                  color: [],
                })
              }
              className="mobile-clear-all"
            >
              CLEAR ALL
            </h5>
          </div>
          <div className="mobile-container">
            <div className="mobile-sidebar">
              <ul className="mobile-filter-list">
                <li
                  className={`mobile-filter-list-item ${
                    activeFilter === "categories" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("categories")}
                >
                  Categories
                </li>
                <li
                  className={`mobile-filter-list-item ${
                    activeFilter === "priceRanges" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("priceRanges")}
                >
                  Price
                </li>
                <li
                  className={`mobile-filter-list-item ${
                    activeFilter === "brands" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("brands")}
                >
                  Brand
                </li>
                <li
                  className={`mobile-filter-list-item ${
                    activeFilter === "colors" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("colors")}
                >
                  Color
                </li>
              </ul>
            </div>
            <div className="mobile-details">
              {filterItems.map((item, index) => (
                <div
                  key={index}
                  className={`mobile-detail-item ${
                    (activeFilter === "categories" &&
                      selectedFilters.categories.includes(item)) ||
                    (activeFilter === "priceRanges" &&
                      selectedFilters.priceRange === item) ||
                    (activeFilter === "brands" &&
                      selectedFilters.brand === item) ||
                    (activeFilter === "colors" &&
                      selectedFilters.color === item)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleFilterSelection(item)}
                >
                  <TiTick
                    style={{
                      fontSize: "18px",
                      marginRight: "15px",
                      visibility:
                        (activeFilter === "categories" &&
                          selectedFilters.categories.includes(item)) ||
                        (activeFilter === "priceRanges" &&
                          selectedFilters.priceRange.includes(item)) ||
                        (activeFilter === "brands" &&
                          selectedFilters.brand.includes(item)) ||
                        (activeFilter === "colors" &&
                          selectedFilters.color.includes(item))
                          ? "visible"
                          : "hidden",
                    }}
                  />
                  {item}
                </div>
              ))}
            </div>
          </div>
          <div className="fixed-bottom-bar">
            <div className="sort-filter-container">
              <div className="sort" onClick={handleFilterClick}>
                CLOSE
              </div>

              <div
                className="sfilter"
                style={{ color: "#e2387c" }}
                onClick={handleFilterApply}
              >
                APPLY
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas
        show={canvashow}
        onHide={handleSortClick}
        placement="bottom"
        className="custom-offcanvas"
      >
        <Offcanvas.Body>
          <h5 className="topfil">SORT BY</h5>
          <hr />
          <div
            className="sort-item"
            onClick={() => handleSortOrder("priceDesc")}
          >
            <FaArrowDownWideShort
              style={{ fontSize: "18px", marginRight: "15px" }}
            />
            Price: High to Low
          </div>
          <div
            className="sort-item"
            onClick={() => handleSortOrder("priceAsc")}
          >
            <FaArrowUpWideShort
              style={{ fontSize: "18px", marginRight: "15px" }}
            />
            Price: Low to High
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Home;
