import React, { useEffect, useState } from "react";
import "./sub.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Subcategoires = () => {
  const [data, setData] = useState([]);
  const subcategory =
    useSelector((state) => state.subcategory.subcategory) || 
    localStorage.getItem("subcat");
  const getProductsBySubCategory = async () => {
    const url =
      process.env.REACT_APP_HOST +
      `product/getProductsBySubCategory/${subcategory}`;
    axios
      .get(url, { subcategory: subcategory })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (subcategory !== "") {
      getProductsBySubCategory();
    }
  }, [subcategory]);

  return (
    <>
      <div className="subcat">
        <div className="main-container">
          <div className="content-left">
            <h4
              className="heading"
              style={{ color: "#1D79C8" }}
              onClick={() => {
                navigate("/");
              }}
            >
              HOME
            </h4>
            <h5 className="heading slas">/</h5>
            <h4 className="heading" style={{ textTransform: "uppercase" }}>
              {subcategory}
            </h4>
          </div>
        </div>

        <div id="home">
          {data &&
            data.map((item, index) => {
              return (
                <div key={index}>
                  <div key={index}>
                    <div
                      className="product-card"
                      onClick={() => {
                        localStorage.setItem("product", item._id);
                        navigate(`/product/${item._id}`);
                        window.location.reload();
                      }}
                    >
                      <div className="product-image">
                        <img
                          src={item.images[0]}
                          className="courousel courimg"
                          alt="Loading..."
                          loading="lazy"
                        />
                      </div>
                      <div className="item-det">
                        <div className="item-mid">
                          <p className="brand">{item.brand}</p>
                        </div>
                        <p className="itemtitle">{item.title}</p>
                        <div className="price-det">
                          <p className="itemdisprice">
                            Rs.{item.discountedPrice}
                          </p>
                          <p className="itemprice">Rs.{item.price}</p>
                          <p className="itemdiscount">
                            ({item.discountPercentage}% OFF)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Subcategoires;
