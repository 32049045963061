import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import axios from "axios";
import AdminHeader from "../Adminheader/Adminheader";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function VendorDashboard() {
  const Global = useContext(AuthContext);
  const [vendor, setVendor] = useState({});
  const [data, setdata] = useState([]);
  const [orders, setorders] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [editId, seteditId] = useState("");
  const [status, setStatus] = useState("");
  const [editproduct, seteditProduct] = useState({
    title: "",
    description: "",
    price: 0,
    discountPercentage: 0,
    discountedPrice: 0,
    brand: "",
    category: "",
  });

  const navigate = useNavigate();

  const fetchVendor = async () => {
    const ID = localStorage.getItem("vendorID");
    let url = process.env.REACT_APP_HOST + `vendor/getvendor/${ID}`;
    try {
      const response = await axios.get(url);
      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching vendors", error);
    }
  };

  const fetchProducts = async () => {
    const ID = localStorage.getItem("vendorID");
    let url = process.env.REACT_APP_HOST + `product/getvendorproducts/${ID}`;
    try {
      const response = await axios.get(url);
      // console.log(response.data);
      setdata(response.data);
    } catch (error) {
      // console.error("Failed to fetch products:", error);
    }
  };

  const fetchOrders = async () => {
    const ID = localStorage.getItem("vendorID");
    let url = process.env.REACT_APP_HOST + `order/getvendororders/${ID}`;
    try {
      const response = await axios.get(url);
      //console.log(response.data);
      setorders(response.data);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  const handleChange = (event) => {
    if (event.target.value !== "") {
      setStatus(event.target.value);
    }
  };

  const UpdateOrder = async (id) => {
    let url = process.env.REACT_APP_HOST + `order/Updateorderstatus/${id}`;
    try {
      const response = await axios.patch(url, { status });
      setStatus("");
      fetchOrders();
    } catch (error) {
      console.error("Error Updating order:", error);
    }
  };

  useEffect(() => {
    fetchVendor();
    fetchProducts();
    fetchOrders();
  }, []);

  const handleShow = (item) => {
    //console.log(item);
    seteditId(item._id);
    seteditProduct({ ...item });
    setLgShow(true);
  };

  const handleupdate = async (e) => {
    e.preventDefault();
    setLgShow(false);
    try {
      let url = process.env.REACT_APP_HOST + `product/updateproduct/${editId}`;
      const response = await axios.patch(url, editproduct);
      alert("product edited successfully!");
    } catch (error) {
      console.error(error.message);
      //   alert("Error Editing address.");
    }
  };

  const handledelete = async (id) => {
    // console.log(id);
    const list = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id !== id) {
        list.push(data[i]);
      }
    }
    setdata([...list]);
    try {
      let url = process.env.REACT_APP_HOST + `product/deleteproduct/${id}`;
      const response = await axios.delete(url);
      alert("product deleted successfully!");
    } catch (error) {
      console.error(error.message);
      alert("Error deleting product.");
    }
  };

  return (
    <>
      <div className="dashboard">
        <header className="dashboard-header">
          <h1>{vendor.name}</h1>
          <h2>{vendor.storeName}</h2>
          <button
            className="logout-b"
            onClick={() => {
              navigate("/BecomeASeller");
            }}
          >
            LOGOUT
          </button>
        </header>
        <div className="dashboard-stats">
          <div className="stat-box">
            <label>Number of Products</label>
            <p>{data.length}</p>
          </div>
          <div className="stat-box">
            <label>Number of Orders</label>
            <p>{orders.length}</p>
          </div>
          <div className="stat-box">
            <button onClick={() => navigate("/AddVendorProduct")}>
              Add New Product
            </button>
          </div>
        </div>
        <Tabs
          defaultActiveKey="profile"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title=" PRODUCTS ">
            <div className="product-list">
              {data &&
                data.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="Admin-card">
                        <div>
                          <div className="Admin-image">
                            <img src={item.images[0]} alt="...loading" />
                          </div>
                        </div>
                        <div className="Admin-details">
                          <h3>{item.title}</h3>
                          <p className="Admin-brand">{item.brand}</p>
                          <p className="Admin-description">
                            {item.description}
                          </p>
                          <p className="Admin-pric">
                            <span className="Admin-discounted-price">
                              &#8377;{item.discountedPrice}
                            </span>
                            <span className="Admin-original-price">
                              {item.price}
                            </span>
                            <span className="Admin-discount">
                              {item.discountPercentage}% OFF
                            </span>
                          </p>
                        </div>
                        <button
                          className="Admin-edit"
                          onClick={() => handleShow(item)}
                        >
                          EDIT
                        </button>
                        <button
                          className="Admin-del"
                          onClick={() => handledelete(item._id)}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Tab>
          <Tab eventKey="profile" title="ORDERS">
            <div className="orders-contain">
              {orders.map((order) => (
                <div key={order._id} className="order-card">
                  <div className="order-info">
                    <h2>Order ID: {order._id}</h2>
                    <p>Status: {order.status}</p>
                    <p>
                      Date: {new Date(order.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="address">
                    <div className="address-header">
                      <h3 className="address-title">
                        {order.shippingAddress.name}{" "}
                        {order.shippingAddress.mobileNumber}
                      </h3>
                    </div>
                    <p className="address-details">
                      {order.shippingAddress.address},{" "}
                      {order.shippingAddress.locality},
                      {order.shippingAddress.landmark},
                      {order.shippingAddress.city},{" "}
                      {order.shippingAddress.state} -
                      {order.shippingAddress.pincode}
                    </p>
                  </div>
                  <div className="products-container">
                    {order.products.map((product, index) => (
                      <div key={index} className="product-car">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imag"
                        />
                        <div className="product-info">
                          <h3>{product.title}</h3>
                          <p>{product.description}</p>
                          <p>
                            Price: ${product.discountedPrice * product.quantity}
                            <span className="original-price">
                              ${product.price}
                            </span>
                          </p>
                          <p>Quantity: {product.quantity}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="status-selector">
                    <select
                      value={status}
                      onChange={handleChange}
                      className="in"
                    >
                      <option value="">Select Status</option>
                      <option value="Order Placed">Order Placed</option>
                      <option value="Order Processing">Order Processing</option>
                      <option value="Order Confirmed">Order Confirmed</option>
                      <option value="Order Packed in Dispatch House">
                        Order Packed in Dispatch House
                      </option>
                      <option value="Shipped to Delivery Center">
                        Shipped to Delivery Center
                      </option>
                      <option value="Arrived at Nearest Delivery Facility">
                        Arrived at Nearest Delivery Facility
                      </option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Order Delivered">Order Delivered</option>
                    </select>
                    <button
                      onClick={() => UpdateOrder(order._id)}
                      style={{
                        marginLeft: "400px",
                        width: "200px",
                        padding: "10px",
                        background: "black",
                        color: "white",
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">EDIT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="addTitle">Title</label>
          <input
            type="text"
            name="title"
            value={editproduct.title}
            className="in"
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                title: e.target.value,
              })
            }
          />
          <label className="addTitle">Description</label>
          <textarea
            className="in"
            name="description"
            value={editproduct.description}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                description: e.target.value,
              })
            }
          ></textarea>
          <label className="addTitle">Price</label>
          <input
            type="number"
            className="in"
            name="price"
            value={editproduct.price}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                price: e.target.value,
              })
            }
          />
          <label className="addTitle">Discount Percentage</label>
          <input
            type="number"
            className="in"
            name="discountPercentage"
            value={editproduct.discountPercentage}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                discountPercentage: e.target.value,
              })
            }
          />
          <label className="addTitle">Discounted Price</label>
          <input
            type="number"
            className="in"
            name="discountedPrice"
            value={editproduct.discountedPrice}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                discountedPrice: e.target.value,
              })
            }
          />
          <label className="addTitle">Brand</label>
          <input
            type="text"
            className="in"
            name="brand"
            value={editproduct.brand}
            onChange={(e) =>
              seteditProduct({
                ...editproduct,
                brand: e.target.value,
              })
            }
          />
          <div>
            <Button variant="primary" onClick={handleupdate}>
              Update
            </Button>{" "}
            <Button variant="dark" onClick={() => setLgShow(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VendorDashboard;
