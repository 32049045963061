import React, { useEffect, useState } from "react";
import "./Profile.css";
import axios from "axios";
import toast from "react-hot-toast";

const Profile = () => {
  const [user, setUser] = useState({
    profilePhoto:
      "https://plus.unsplash.com/premium_photo-1677252438411-9a930d7a5168?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1pbi1zYW1lLXNlcmllc3wxfHx8ZW58MHx8fHx8",
    firstName: "John",
    lastName: "Doe",
    gender: "Male",
    phoneNumber: "123-456-7890",
    email: "john.doe@example.com",
    User: localStorage.getItem("sub"),
  });
  const [isEditing, setIsEditing] = useState(false);
  const [btn, setbtn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "profilePhoto") {
      setUser({ ...user, [name]: e.target.files[0] });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const getProfile = async () => {
    let User = localStorage.getItem("sub");
    const token = sessionStorage.getItem("Token");
    let url = process.env.REACT_APP_HOST + `profile/getprofile/${User}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      if (response.data.length > 0) {
        setUser(response.data[0]);
        setbtn(true);
      }
    } catch (error) {
      console.error("Error fetching profile", error);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleEditToggle = () => setIsEditing(!isEditing);

  const handlesave = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("Token");
    setIsEditing(false);
    setUser({ ...user, User: localStorage.getItem("sub") });
    const formData = new FormData();
    Object.keys(user).forEach((key) => formData.append(key, user[key]));
    let url = process.env.REACT_APP_HOST + `profile/create-profile`;
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      getProfile();
      // Optionally, handle the state change to reflect the updated profile
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleupdate = async (e) => {
    e.preventDefault();
    const token = sessionStorage.getItem("Token");
    toast.loading("...Loading");
    setTimeout(setIsEditing(false), 5000);
    setUser({ ...user, User: localStorage.getItem("sub") });
    const formData = new FormData();
    Object.keys(user).forEach((key) => formData.append(key, user[key]));
    let url = process.env.REACT_APP_HOST + `profile/update-profile`;
    try {
      const response = await axios.patch(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response) {
        toast.dismiss();
      }
      getProfile();
      // Optionally, handle the state change to reflect the updated profile
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <>
      {/* <Spinner /> */}
      <div className="profile-container">
        <div className="profile-header">
          <h5>PROFILE</h5>
          <button onClick={handleEditToggle} className="edit-btn">
            {isEditing ? "Cancel" : "Edit"}
          </button>
        </div>
        {isEditing ? (
          <div className="midd1">
            <div className="middle">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={user.firstName}
                onChange={handleChange}
                className="pf"
              />
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={user.lastName}
                onChange={handleChange}
                className="pf"
              />

              <label>Gender</label>
              <select
                name="gender"
                value={user.gender}
                onChange={handleChange}
                className="pf"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label>Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleChange}
                className="pf"
              />
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={user.email}
                onChange={handleChange}
                className="pf"
              />
              <div>
                {btn ? (
                  <button
                    onClick={(e) => handleupdate(e)}
                    className="save-btn"
                    style={{ marginTop: "10px" }}
                  >
                    update
                  </button>
                ) : (
                  <button onClick={(e) => handlesave(e)} className="save-btn">
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <hr />
            <div className="profile-contained">
              <div className="profile-card">
                <div className="profile-info">
                  <h5>DETAILS</h5>
                  <hr />
                  <span className="label">Full Name</span>
                  <div className="info-item">
                    <span className="inn">
                      {user.firstName} {user.lastName}
                    </span>
                  </div>
                  <span className="label">Phone Number</span>
                  <div className="info-item">
                    <span className="inn">{user.phoneNumber}</span>
                  </div>
                  <span className="label">Email Address</span>
                  <div className="info-item">
                    <span className="inn">{user.email}</span>
                  </div>
                  <span className="label">Gender</span>
                  <div className="info-item">
                    <span className="inn">{user.gender}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Profile;
