import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "../Adminheader/Adminheader";
import { useNavigate } from "react-router-dom";
import "./vendor.css";
import AuthContext from "../../context/Auth/AuthContext";

function VendorList() {
  const [vendors, setVendors] = useState([]);
  const navigate = useNavigate();
  const Global = useContext(AuthContext);

  useEffect(() => {
    const fetchVendors = async () => {
      let url = process.env.REACT_APP_HOST + "vendor/getvendors";
      try {
        const response = await axios.get(url);
        setVendors(response.data);
      } catch (error) {
        console.error("Error fetching vendors", error);
      }
    };
    fetchVendors()
  }, []);

  const handleregister = () => {
    navigate("/vendorForm");
  };

  const handleprofile = (vendor) => {
      Global.setvendor_id(vendor._id);
      localStorage.setItem("vendorID" , vendor._id)
      navigate("/vendorProfile")
  };

  return (
    <div>
      <AdminHeader />
      <div>
        <table className="vendor-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>PHONE</th>
              <th>STORE</th>
              <th>PROFILE</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor) => (
              <tr key={vendor._id}>
                <td>{vendor.name}</td>
                <td>{vendor.phoneNumber}</td>
                <td>{vendor.storeName}</td>
                <td>
                  <button
                    onClick={() => {
                      handleprofile(vendor);
                    }}
                  >
                    PROFILE
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="register-button" onClick={() => handleregister()}>
          REGISTER
        </button>
      </div>
    </div>
  );
}

export default VendorList;
