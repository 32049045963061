import React, { useEffect, useState } from "react";
import axios from "axios";
import "./order.css";
import Modal from "react-bootstrap/Modal";
import Tracker from "../tracker/tracker";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/spinner";

const Order = () => {
  const [data, setdata] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const [show, setshow] = useState({});
  const navigate = useNavigate();

  const fetchOrders = async () => {
    let User = localStorage.getItem("sub");
    const token = sessionStorage.getItem("Token");
    let url = process.env.REACT_APP_HOST + `order/getorders/${User}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const handleordercancel = async (id) => {
    let url = process.env.REACT_APP_HOST + `order/cancelOrder/${id}`;
    try {
      const response = await axios.patch(url);
      console.log(response.data);
      // setdata(response.data);
    } catch (error) {
      console.error("Error Cancelling order:", error);
    }
  };

  const givenDateString = "2024-04-10T13:43:54.806Z";

  const givenDate = new Date(givenDateString);

  const today = new Date();

  today.setHours(0, 0, 0, 0);

  const differenceInMilliseconds = givenDate - today;

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  const handle = (order) => {
    setshow(order);
    setLgShow(!lgShow);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  if (!data.length) {
    return (
      <>
        <Spinner />

        <div className="empty-wishlist-container">
          <img
            src="/images/preview.png"
            alt="Empty Wishlist"
            className="wishlist-image"
          />
          <h2>Empty Orders</h2>
          <p>You haven't ordered anything yet ! start shopping now</p>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bth"
          >
            Back To Homepage
          </button>
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        <Spinner />

        <div className="orders-container">
          {data.map((order) => (
            <div
              key={order._id}
              className="order-cardd"
              onClick={() => handle(order)}
            >
              <div className="products-containe">
                {order.products.map((product, index) => (
                  <div key={index} className="product-cardd">
                    <img
                      src={product.images[0]}
                      alt={product.title}
                      className="product-imaged"
                    />
                    <div className="product-info">
                      <h3 className="product-title">{product.title}</h3>
                      <p className="product-description">
                        {product.description}
                      </p>

                      <p>Quantity: {product.quantity}</p>
                    </div>
                    <p className="discounted-price">
                      ${product.discountedPrice * product.quantity}{" "}
                      {/* <span className="original-price">${product.price}</span> */}
                    </p>
                    {/* <p style={{ textTransform: "uppercase" }}>{order.status}</p> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              ORDER AND SHIPPING DETAILS
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {show._id ? (
              <>
                <div className="order-info">
                  <h2>Order ID: {show._id}</h2>
                  <p>Date: {new Date(show.createdAt).toLocaleDateString()}</p>
                </div>
                <div>
                  <div className="add">
                    <div className="addinne">
                      <h3 className="add-title">
                        {show.shippingAddress.name}{" "}
                        {show.shippingAddress.mobileNumber}
                      </h3>
                    </div>
                    <p className="add-addrss" style={{ margin: "0px" }}>
                      {show.shippingAddress.address},
                      {show.shippingAddress.locality},{show.landmark}
                      {show.shippingAddress.city} , {show.shippingAddress.state}
                      -{show.shippingAddress.pincode}
                    </p>
                  </div>
                </div>
                <div className="products-containe">
                  {show &&
                    show.products.map((product, index) => (
                      <div key={index} className="product-cardd">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imaged"
                        />
                        <div className="product-info">
                          <h3 className="product-title">{product.title}</h3>
                          <p className="product-description">
                            {product.description}
                          </p>
                          <p>Quantity: {product.quantity}</p>
                        </div>
                        <p className="discounted-price">
                          ${product.discountedPrice * product.quantity}{" "}
                        </p>
                      </div>
                    ))}
                  <Tracker status={show.status} waybill={show.waybill} />
                </div>
                {show.cancellation ? (
                  <div className="button-container">
                    <button
                      className="qwe"
                      onClick={() => handleordercancel(show._id)}
                    >
                      CANCEL ORDER
                    </button>
                  </div>
                ) : show.returnable ? (
                  <div className="button-container">
                    <button className="qwe">RETURN ORDER</button>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Order;
