import React from 'react'

const Failure = () => {
  return (
    <div className="failure-container">
      <div className="failure-content">
        <div className="failure-icon">
          <svg
            width="100"
            height="100"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#dc3545" />
            <path
              d="M16 8l-8 8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 8l8 8"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h1 className="failure-title">Payment Failed</h1>
        <p className="failure-message">
          Unfortunately, your payment could not be processed. Please try again
          or contact support if the issue persists.
        </p>
        <a href="/" className="failure-button">
          Return to Home
        </a>
      </div>
    </div>
  );
}

export default Failure
