import React from "react";
import "./seller.css"; // Make sure the CSS is linked
import Footer from "../Footer/footer";
import {  useNavigate } from "react-router-dom";
import Spinner from "../Spinner/spinner"

const BecomeASeller = () => {
  const navigate = useNavigate()
  // const handleselling = () => {
  //   navigate("/vendorForm");
  // }
  return (
    <>
      <Spinner />
      <div className="seller-container">
        <div className="banner">
          <img src="./images/ss1.jpg" alt="Join Our Seller Community" />
          <div className="banner-text">
            <h1>Join Our Seller Community</h1>
            <h5>Turn your passion into profit with Sportsmart</h5>
          </div>
          <div className="banner-buttons">
            <button
              className="login-btn"
              onClick={() => {
                navigate("/VendorLogin");
              }}
            >
              Login
            </button>
            <button
              className="start-selling-btn"
              onClick={() => {
                navigate("/vendorForm");
              }}
            >
              Start Selling
            </button>
          </div>
        </div>
        <div className="intro-section">
          <h1 className="intro-title">Welcome to Our Seller Community!</h1>
          <p className="intro-text">
            At Sportsmart, we believe in empowering creators, artisans, and
            entrepreneurs by providing a platform that amplifies your reach and
            connects you with millions of customers. Join us to expand your
            business, boost your brand visibility, and unlock new opportunities.
          </p>
        </div>

        <div className="container">
          <div className="features-container">
            <div className="feature-box">
              <h3 className="feature-title">Reach a Wider Audience</h3>
              <p className="feature-text">
                Leverage our extensive customer base to get your products in
                front of a global audience.
              </p>
            </div>
            <div className="feature-box">
              <h3 className="feature-title">User-Friendly Platform</h3>
              <p className="feature-text">
                Enjoy seamless listing, inventory management, and sales tracking
                through our state-of-the-art seller dashboard.
              </p>
            </div>
            <div className="feature-box">
              <h3 className="feature-title">Dedicated Support</h3>
              <p className="feature-text">
                Our seller support team is always available to help you with any
                questions or challenges you may encounter.
              </p>
            </div>
            <div className="feature-box">
              <h3 className="feature-title">Flexible Operations</h3>
              <p className="feature-text">
                Choose the logistics that work best for you—handle your own
                shipping or use our comprehensive fulfillment services.
              </p>
            </div>
          </div>
          <div className="image-container">
            <img src="./images/ss2.jpg" alt="Feature" />
          </div>
        </div>
        <p className="partnership-announcement">
          At Sportsmart, we continuously strive to enhance your shopping
          experience and streamline our delivery processes. We are excited to
          announce our partnership with iThink Logistics, a leading provider
          known for their innovative and efficient shipping solutions.
        </p>

        <div className="logistics-features">
          <h2 className="features-title">Why iThink Logistics?</h2>

          <div className="feature-item">
            <h3 className="feature-heading">Speed and Efficiency</h3>
            <p className="feature-description">
              iThink Logistics utilizes state-of-the-art technology to ensure
              fast and reliable delivery of your orders, so you get what you
              need, when you need it.
            </p>
          </div>

          <div className="feature-item">
            <h3 className="feature-heading">Advanced Tracking</h3>
            <p className="feature-description">
              Stay updated with real-time tracking that allows you to follow
              your order from our warehouse right to your doorstep.
            </p>
          </div>

          <div className="feature-item">
            <h3 className="feature-heading">Cost-Effective Shipping</h3>
            <p className="feature-description">
              Through smart logistics solutions, we are able to reduce shipping
              costs, savings that we pass directly onto you, our valued
              customer.
            </p>
          </div>

          <div className="feature-item">
            <h3 className="feature-heading">Wide Reach</h3>
            <p className="feature-description">
              Whether you're in a bustling city or a remote corner, iThink
              Logistics' extensive network ensures no location is out of reach.
            </p>
          </div>
        </div>
        <p className="cta-text">
          Sign up, list your products, and start selling today. Let us help you
          grow your business and make every sale smoother and more rewarding.
        </p>
        <h3 className="cta-heading">
          Join our community of successful sellers and turn your passion into
          profit. Start selling with Sportsmart—where your business meets
          boundless potential.
        </h3>
      </div>
      <Footer />
    </>
  );
};

export default BecomeASeller;
