import { createSlice } from "@reduxjs/toolkit";

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategory: "",
    status: null,
    error: null,
  },
  reducers: {
    setSubCategory: (state, action) => {
      state.subcategory = action.payload;
    },
  },
});

export default subcategorySlice.reducer;

export const { setSubCategory } = subcategorySlice.actions;
