import React, { useEffect, useState } from "react";
import "./Card.css";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import { useAuth0 } from "@auth0/auth0-react";
import { FaRegHeart } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { addToWishList } from "../../Store/Features/wishlistSlice";
import { useNavigate } from "react-router-dom";

const Card = ({ item }) => {
  const { user } = useAuth0();
  const [isHovering, setIsHovering] = useState(false);
  const [isWishlist, setIsWishlist] = useState(false);
  const [AddToWishlist, setAddToWishlist] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [touchStartX, setTouchStartX] = useState(0);
  const [touchStartY, setTouchStartY] = useState(0);
  const touchThreshold = 10; 

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchEndY = e.changedTouches[0].clientY;

    const diffX = Math.abs(touchEndX - touchStartX);
    const diffY = Math.abs(touchEndY - touchStartY);

    if (diffX < touchThreshold && diffY < touchThreshold) {
      localStorage.setItem("product", item._id);
      navigate(`/product/${item._id}`);
      window.location.reload();
    }
  };

  const check = async () => {
    let User = localStorage.getItem("sub");
    setIsWishlist(false);
    if (item?.wishlistedUsers?.includes(User)) {
      setIsWishlist(true);
    }
  };
  
  useEffect(() => {
    check();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [item._id]);

  const onAddToWishlist = async () => {
    if (user) {
      setAddToWishlist(true);
      dispatch(addToWishList(item))
        .then((res) => {
          toast.success(res.payload.message);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error("Please do login");
    }
  };

  return (
    <div
      className="product-card"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        localStorage.setItem("product", item._id);
        navigate(`/product/${item._id}`);
        window.location.reload();
      }}
    >
      <div
        className="product-image"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <Carousel
          autoPlay={isHovering}
          interval={1000}
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          swipeable={!isMobile} 
        >
          {item.images.map((img, index) => {
            const transformedImg = img.replace("/upload/", "/upload/q_20/");
            return (
              <div key={index}>
                <img
                  src={transformedImg}
                  className="courousel courimg"
                  alt="Loading..."
                  loading="lazy"
                />
              </div>
            );
          })}
        </Carousel>

        {isHovering && (
          <div className="product-hover">
            {isWishlist || AddToWishlist ? (
              <button
                className="wishlisted-button"
                onClick={(e) => e.stopPropagation()}
              >
                <span style={{ marginRight: "8px" }}>
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{
                      fontSize: "18px",
                      color: "rgb(244, 65, 121)",
                    }}
                  />
                </span>
                WISHLISTED
              </button>
            ) : (
              <button
                className="wishlists-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddToWishlist();
                }}
              >
                <span style={{ marginRight: "8px" }}>
                  <FaRegHeart
                    style={{ fontSize: "17px", marginBottom: "2px" }}
                  />
                </span>
                WISHLIST
              </button>
            )}
          </div>
        )}
      </div>
      <div className="item-det">
        <div className="item-mid">
          <p className="brand">{item.brand}</p>
          {isWishlist || AddToWishlist ? (
            <span className="likesym">
              <FontAwesomeIcon
                icon={faHeart}
                style={{
                  fontSize: "24px",
                  color: "rgb(244, 65, 121)",
                }}
              />
            </span>
          ) : (
            <span
              className="likesym"
              onClick={(e) => {
                e.stopPropagation();
                onAddToWishlist();
              }}
            >
              <FaRegHeart style={{ fontSize: "23px", marginBottom: "2px" }} />
            </span>
          )}
        </div>
        <p className="itemtitle">{item.title}</p>
        <div className="price-det">
          <p className="itemdisprice">Rs.{item.discountedPrice}</p>
          <p className="itemprice">Rs.{item.price}</p>
          <p className="itemdiscount">({item.discountPercentage}% OFF)</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
