import React from "react";

const Success = () => {
  return (
    <div className="success-container">
      <div className="success-content">
        <div className="success-icon">
          <svg
            width="100"
            height="100"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="10" fill="#28a745" />
            <path
              d="M16 9l-4 4-2-2"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <h1 className="success-title">Payment Successful</h1>
        <p className="success-message">
          Thank you for your purchase! Your transaction was completed
          successfully.
        </p>
        <a href="/" className="success-button">
          Return to Home
        </a>
      </div>
    </div>
  );
};

export default Success;
