import React, { useEffect } from "react";
import "./wishlist.css";
import toast from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../Footer/footer";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWishList,
  deleteWishItem,
} from "../../Store/Features/wishlistSlice";
import { addToCart } from "../../Store/Features/cartSlice";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner/spinner";

const Wishlist = () => {
  const dispatch = useDispatch();
  const wishlistitems = useSelector((state) => state.Wishlist.items);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchWishList());
  }, []);

  const onAddToCart = async (item) => {
    dispatch(addToCart(item.Product)).then(() => {
      toast.success("Item moved to Bag");
      dispatch(deleteWishItem(item._id));
    });
  };
  const onRemoveFromwish = async (item) => {
    dispatch(deleteWishItem(item._id));
  };

  if (!wishlistitems.length) {
    return (
      <>
        <Spinner />
        <div className="empty-wishlist-container">
          <img
            src="/images/empty.png"
            alt="Empty Wishlist"
            className="wishlist-image"
          />
          <h2>Empty Wishlist</h2>
          <p>You have no items in your wishlist. Start adding!</p>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bth"
          >
            Back To Homepage
          </button>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Spinner />
      <div id="home">
        {wishlistitems &&
          wishlistitems.map((item, index) => (
            <div
              className="product-card"
              style={{ paddingBottom: "1px" }}
              key={index}
            >
              <div>
                <div className="product-image">
                  <img
                    src={item.Product.images[0]}
                    className="courousel courimg"
                  />
                </div>
              </div>
              <div className="item-det">
                <div className="item-mid">
                  <p className="brand">{item.Product.brand}</p>
                </div>
                <p className="itemtitle">{item.Product.title}</p>
                <div className="price-det">
                  <p className="itemdisprice">
                    Rs. {item.Product.discountedPrice}
                  </p>
                  <p className="itemprice">Rs. {item.Product.price}</p>
                  <p className="itemdiscount">
                    ({item.Product.discountPercentage}% OFF)
                  </p>
                </div>
              </div>
              <button className="Mtg" onClick={() => onAddToCart(item)}>
                MOVE TO BAG
              </button>
              <div className="crossx" onClick={() => onRemoveFromwish(item)}>
                <RxCross2 style={{ fontSize: "15px" }} />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Wishlist;
