import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, ProgressBar } from "react-bootstrap";
import AdminHeader from "../Adminheader/Adminheader";
import Footer from "../../components/Footer/footer";
import "./Addproduct.css";
import axios from "axios";

const sportsCategories = {
  CRICKET: [
    "Cricket Bats",
    "Batting Gloves",
    "Batting Pads",
    "Cricket Bags",
    "Cricket Helmets",
    "Cricket Protective",
    "Wicket Keeping",
    "Cricket Balls",
    "Cricket Accessories",
    "Cricket Shoes",
    "Cricket Clothing",
    "Cricket Supporter Wear",
    "Cricket Others",
  ],
  FOOTBALL: [
    "Football Boots",
    "Football Jerseys",
    "Football Shorts",
    "Football Socks",
    "Goalkeeper Gloves",
    "Football Balls",
    "Training Equipment",
    "Football Accessories",
    "Football Others",
  ],
  BADMINTON: [
    "Badminton Racquets",
    "Badminton Accessories",
    "Squash Racquets",
    "Squash Accessories",
    "Court Shoes",
    "Badminton Others",
  ],
  TENNIS: [
    "Adult Tennis Racquets",
    "Junior Tennis Racquets",
    "Tennis Balls",
    "Tennis Bags",
    "Tennis Accessories",
    "Tennis Clothing",
    "Tennis Shoes",
    "Pickleball",
    "Tennis Others",
  ],
  HOCKEY: [
    "Hockey Sticks",
    "Hockey Balls",
    "Hockey Bags",
    "Hockey Protective & Accessories",
    "Hockey Shoes",
    "Hockey Others",
  ],
  OTHERSPORTS: [
    "Squash Rackets",
    "Boxing, Karate, Wrestling",
    "Chess",
    "Swimming",
    "Pickleball Paddle",
    "Others",
  ],
};

const colorOptions = ["Red", "Blue", "Black", "White", "Green", "Others"];

function AddProduct() {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(25);
  const [product, setProduct] = useState({
    productName: "",
    productCategory: "",
    productSubCategory: "",
    productDescription: "",
    shortDescription: "",
    manufacturer: "",
    brand: "",
    productID: "",
    publishStatus: false,
    color: "",
    stockLevel: 0,
    productImages: [],
    seo: {
      metaTitle: "",
      metaDescription: "",
      metaKeywords: [],
      urlSlug: "",
      altText: "",
    },
    pricing: {
      price: 0,
      costPrice: 0,
      salePrice: 0,
      discountPercent: 0,
    },
    shippingInfo: {
      shippingClass: "",
      sku: "",
      weight: 0,
      Length: 0,
      width: 0,
      height: 0,
      handlingTime: 0,
      restrictions: "",
    },
    complianceInfo: {
      warrantyDetails: "",
      safetyInformation: "",
      returnPolicies: "",
    },
  });
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length === 1) {
      setProduct({ ...product, [name]: value });
    } else {
      const newObject = { ...product[keys[0]], [keys[1]]: value };
      setProduct({ ...product, [keys[0]]: newObject });
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const imageFiles = selectedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (imageFiles.length < selectedFiles.length) {
      setError("Only images (JPEG, PNG, GIF) are allowed.");
    } else {
      setError("");
    }

    if (imageFiles.length > 0) {
      setProduct({
        ...product,
        productImages: [...product.productImages, ...imageFiles],
      });
    }
  };

  const removeImage = (index) => {
    const updatedImages = [...product.productImages];
    updatedImages.splice(index, 1);
    setProduct({ ...product, productImages: updatedImages });
  };

  const nextStep = () => {
    setStep(step + 1);
    setProgress(progress + 25);
  };

  const prevStep = () => {
    setStep(step - 1);
    setProgress(progress - 25);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);

    const formData = new FormData();

    // Iterate over the product object
    for (const key in product) {
      if (key === "productImages") {
        // Handling images (array of files)
        product[key].forEach((file) => formData.append(key, file));
      } else if (
        typeof product[key] === "object" &&
        !Array.isArray(product[key])
      ) {
        // Handle nested objects (pricing, shippingInfo, complianceInfo)
        for (const subKey in product[key]) {
          formData.append(`${key}.${subKey}`, product[key][subKey]);
        }
      } else if (Array.isArray(product[key])) {
        // Convert arrays (if applicable) to JSON strings
        formData.append(key, JSON.stringify(product[key]));
      } else {
        // Handle all other fields
        formData.append(key, product[key]);
      }
    }

    // Debug FormData to ensure all fields are correctly appended
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const url = `${process.env.REACT_APP_HOST}product/addProduct`;

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        // Successfully added the product
        alert("Product added successfully!");
        navigate("/adminproduct");
      } else {
        // Error with response status but no throw (e.g., 4xx, 5xx)
        console.error("Error:", response.status, response.data);
        alert(`Failed to add product: ${response.data.message}`);
      }
    } catch (error) {
      // Handle errors in the axios request
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Error response:", error.response);
        alert(`Error adding product: ${error.response.data.message}`);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
        alert("No response received from the server.");
      } else {
        // Something else happened
        console.error("Error:", error.message);
        alert(`Error adding product: ${error.message}`);
      }
    }
  };

  return (
    <>
      <AdminHeader />
      <div className="multi-step-container">
        <h2 className="vendor-form-title">Add New Product</h2>
        <ProgressBar now={progress} label={`${progress}%`} className="mb-4" />

        <Form onSubmit={handleSubmit}>
          {/* Step 1: Basic Info */}
          {step === 1 && (
            <div>
              <h4 className="step-title">Step 1: Basic Info</h4>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridProductName">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="productName"
                    value={product.productName}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridBrand">
                  <Form.Label>Brand</Form.Label>
                  <Form.Control
                    type="text"
                    name="brand"
                    value={product.brand}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridShortDescription">
                  <Form.Label>Short Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="shortDescription"
                    value={product.shortDescription}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDescription">
                  <Form.Label>Product Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="productDescription"
                    value={product.productDescription}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridManufacturer">
                  <Form.Label>Manufacturer</Form.Label>
                  <Form.Control
                    type="text"
                    name="manufacturer"
                    value={product.manufacturer}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridProductID">
                  <Form.Label>Product ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="productID"
                    value={product.productID}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Button variant="primary" onClick={nextStep} className="btn-next">
                Next
              </Button>
            </div>
          )}

          {/* Step 2: Category & Images */}
          {step === 2 && (
            <div>
              <h4 className="step-title">Step 2: Category & Images</h4>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridCategory">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="productCategory"
                    value={product.productCategory}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {Object.keys(sportsCategories).map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {product.productCategory && (
                  <Form.Group as={Col} controlId="formGridSubcategory">
                    <Form.Label>Subcategory</Form.Label>
                    <Form.Control
                      as="select"
                      name="productSubCategory"
                      value={product.productSubCategory}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {sportsCategories[product.productCategory].map(
                        (subcategory) => (
                          <option key={subcategory} value={subcategory}>
                            {subcategory}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </Form.Group>
                )}

                <Form.Group as={Col} controlId="formGridStockLevel">
                  <Form.Label>Stock Level</Form.Label>
                  <Form.Control
                    type="number"
                    name="stockLevel"
                    value={product.stockLevel}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridColor">
                  <Form.Label>Color</Form.Label>
                  <Form.Control
                    type="text"
                    name="color"
                    value={product.color}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPublishStatus">
                  <Form.Label>Publish Status</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Publish"
                    name="publishStatus"
                    checked={product.publishStatus}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        publishStatus: e.target.checked,
                      })
                    }
                  />
                </Form.Group>
              </Row>

              <div className="image-preview-container">
                {product.productImages.length > 0 &&
                  product.productImages.map((image, index) => (
                    <div key={index} className="image-preview">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="Preview"
                        className="image-thumbnail"
                      />
                      <button
                        type="button"
                        className="remove-image-btn"
                        onClick={() => removeImage(index)}
                      >
                        X
                      </button>
                    </div>
                  ))}
              </div>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridImages">
                  <Form.Label>Upload Images</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileChange}
                  />
                </Form.Group>
                <p style={{ color: "red", paddingBottom: "5px" }}>{error}</p>
              </Row>

              <Button variant="secondary" onClick={prevStep} className="me-3">
                Back
              </Button>
              <Button variant="primary" onClick={nextStep} className="btn-next">
                Next
              </Button>
            </div>
          )}

          {/* Step 3: Pricing & SEO */}
          {step === 3 && (
            <div>
              <h4 className="step-title">Step 3: Pricing & SEO</h4>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="pricing.price"
                    value={product.pricing.price}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCostPrice">
                  <Form.Label>Cost Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="pricing.costPrice"
                    value={product.pricing.costPrice}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSalePrice">
                  <Form.Label>Sale Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="pricing.salePrice"
                    value={product.pricing.salePrice}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridDiscountPercent">
                  <Form.Label>Discount Percent</Form.Label>
                  <Form.Control
                    type="number"
                    name="pricing.discountPercent"
                    value={product.pricing.discountPercent}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridMetaTitle">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="seo.metaTitle"
                    value={product.seo.metaTitle}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridMetaDescription">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="seo.metaDescription"
                    value={product.seo.metaDescription}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridMetaKeywords">
                  <Form.Label>Meta Keywords</Form.Label>
                  <Form.Control
                    type="text"
                    name="seo.metaKeywords"
                    value={product.seo.metaKeywords}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridUrlSlug">
                  <Form.Label>URL Slug</Form.Label>
                  <Form.Control
                    type="text"
                    name="seo.urlSlug"
                    value={product.seo.urlSlug}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Button variant="secondary" onClick={prevStep} className="me-3">
                Back
              </Button>
              <Button variant="primary" onClick={nextStep} className="btn-next">
                Next
              </Button>
            </div>
          )}

          {/* Step 4: Shipping & Compliance */}
          {step === 4 && (
            <div>
              <h4 className="step-title">Step 4: Shipping & Compliance</h4>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridWeight">
                  <Form.Label>Weight</Form.Label>
                  <Form.Control
                    type="number"
                    name="shippingInfo.weight"
                    value={product.shippingInfo.weight}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridDimensionsLength">
                  <Form.Label>Length</Form.Label>
                  <Form.Control
                    type="number"
                    name="shippingInfo.Length"
                    value={product.shippingInfo.Length}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridDimensionsWidth">
                  <Form.Label>Width</Form.Label>
                  <Form.Control
                    type="number"
                    name="shippingInfo.width"
                    value={product.shippingInfo.width}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridDimensionsHeight">
                  <Form.Label>Height</Form.Label>
                  <Form.Control
                    type="number"
                    name="shippingInfo.height"
                    value={product.shippingInfo.height}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridShippingClass">
                  <Form.Label>Shipping Class</Form.Label>
                  <Form.Control
                    type="text"
                    name="shippingInfo.shippingClass"
                    value={product.shippingInfo.shippingClass}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridHandlingTime">
                  <Form.Label>Handling Time</Form.Label>
                  <Form.Control
                    type="number"
                    name="shippingInfo.handlingTime"
                    value={product.shippingInfo.handlingTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridHandlingTime">
                  <Form.Label>SKU</Form.Label>
                  <Form.Control
                    type="text"
                    name="shippingInfo.sku"
                    value={product.shippingInfo.sku}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridWarrantyDetails">
                  <Form.Label>Warranty Details</Form.Label>
                  <Form.Control
                    type="text"
                    name="complianceInfo.warrantyDetails"
                    value={product.complianceInfo.warrantyDetails}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridSafetyInformation">
                  <Form.Label>Safety Information</Form.Label>
                  <Form.Control
                    type="text"
                    name="complianceInfo.safetyInformation"
                    value={product.complianceInfo.safetyInformation}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridReturnPolicies">
                  <Form.Label>Return Policies</Form.Label>
                  <Form.Control
                    type="text"
                    name="complianceInfo.returnPolicies"
                    value={product.complianceInfo.returnPolicies}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Button variant="secondary" onClick={prevStep} className="me-3">
                Back
              </Button>
              <Button variant="success" type="submit" className="btn-submit">
                Submit
              </Button>
            </div>
          )}
        </Form>
      </div>
      <Footer />
    </>
  );
}

export default AddProduct;
