import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import "./checkout.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "../Footer/footer";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import { Alert, CardActions } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItem,
  fetchCart,
  fetchCartTotal,
  updateCartItemMinus,
  updateCartItemPlus,
} from "../../Store/Features/cartSlice";
import {
  addNewAddress,
  EditAddress,
  fetchAddress,
} from "../../Store/Features/addressSlice";

const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
const Checkout = () => {
  const addresses = useSelector((state) => state.Address.addresses);
  const cartItems = useSelector((state) => state.Cart.items);
  const cartTotal = useSelector((state) => state.Cart.total);
  const cartDiscount = useSelector((state) => state.Cart.discount);
  const cartPayable = useSelector((state) => state.Cart.payable);
  const cartTotalItems = useSelector((state) => state.Cart.totalItems);
  const [showForm, setShowForm] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [show, setShow] = useState(false);
  const [editId, seteditId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [newAddress, setNewAddress] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    landmark: "",
    email: "",
    User: localStorage.getItem("sub"),
  });
  const [editAddress, seteditAddress] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    landmark: "",
  });
  const [order, setOrder] = useState({
    User: localStorage.getItem("sub"),
    Address: "",
  });
  const randomString = Math.random().toString(36).slice(8);
  const [captcha, setCaptcha] = useState(randomString);
  const [text, setText] = useState("");
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const refreshString = () => {
    setText("");
    setCaptcha(Math.random().toString(36).slice(8));
  };

  const matchCaptcha = (event) => {
    event.preventDefault();
    if (text === captcha) {
      setValid(false);
      setSuccess(true);
    } else {
      setValid(true);
      setSuccess(false);
    }
  };

  const navigate = useNavigate();

  const handleClose = () => setShow(false);

  const handleShow = (address) => {
    seteditId(address._id);
    seteditAddress({ ...address });
    setShow(true);
  };

  const handleDeliverHereClick = (id) => {
    setActiveTab("summary"); // Change the active tab to 'ORDER SUMMARY'
    setOrder({ ...order, Address: id });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleNewAddress = async (e) => {
    e.preventDefault();
    setShowForm(!showForm);
    dispatch(addNewAddress(newAddress))
      .unwrap()
      .then(() => {
        toast.success("Address added successfully!");
        setActiveTab("summary");
      })
      .catch(() => {
        toast.error("Error adding address.");
      });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setShow(false);
    dispatch(EditAddress({ editId, editAddress: editAddress }))
      .unwrap()
      .then(() => {
        toast.success("Address edited successfully!");
      })
      .catch(() => {
        toast.error("Error editing address.");
      });
  };
  //  tabbbb-22222

  useEffect(() => {
    dispatch(fetchAddress());
  }, []);

  useEffect(() => {
    dispatch(fetchCart());
    dispatch(fetchCartTotal());
  }, [cartItems]);

  const removeFromCart = async (item) => {
    dispatch(deleteCartItem(item));
  };

  const decrementQuantity = async (item) => {
    dispatch(updateCartItemMinus(item));
  };

  const incrementQuantity = async (item) => {
    dispatch(updateCartItemPlus(item));
  };

  const handleOrderCart = async () => {
    let User = localStorage.getItem("sub");
    const token = sessionStorage.getItem("Token");
    let url = process.env.REACT_APP_HOST + `cart/getcartitems/${User}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setActiveTab("payment");
    } catch (error) {
      //console.error("Error fetching cartItems:", error);
    }
  };

  const handlePaymentChange = (payment) => {
    // if (paymentMethod == "") {
    //   setPaymentMethod("COD");
    // } else {
    //   setPaymentMethod("");
    // }
    setPaymentMethod(payment)
  };

  const handleConfirmOrder = () => {
      if(paymentMethod == "COD"){
        handleCOD()
      }else if(paymentMethod == "CARD"){
        handlestripe()
      }
  }

  const handleCOD = async () => {
    if (!success) {
      toast.error("Please validate the Captcha");
      return;
    }

    let user = localStorage.getItem("sub");
    const token = sessionStorage.getItem("Token");

    if (!user) {
      toast.error("User not found. Please log in.");
      return;
    }

    if (!token) {
      toast.error("Authentication failed. Please log in.");
      return;
    }

    // Update the order with user information
    setOrder({ ...order, User: user });

    let url = `${process.env.REACT_APP_HOST}order/create`;
    toast.loading("...Please Wait")
    try {
      const response = await axios.post(url, order, {
        headers: {
          "Content-Type": "application/json", // Set this unless you're sure you need multipart/form-data
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss()
      toast.success("Order created successfully");
      localStorage.removeItem("Checkout"); // Clean up after successful order
      navigate("/orders");
    } catch (error) {
      if (error.response) {
        // Server-side error
        console.log(error);

        toast.error(`Failed to create order: ${error.response.data.message}`);
      } else if (error.request) {
        // Network error or no response from server
        toast.error("Network error: Failed to communicate with the server");
      } else {
        // Other unknown errors
        toast.error("Failed to create order");
      }
    }
  };

  const handlestripe = async () => {
    const stripe = await loadStripe(
      "pk_test_51PoUn1RoLR0fsTe2T1XRjcQ5C3IVrOiDQtoWAwSQkdOqLrx073vIroGsnD6LXgEQZvfejSqET3fqJtKNLdspxvqI00lagCLMlk"
    );

    const body = {
      Products: cartItems,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    let url = process.env.REACT_APP_HOST + `order/Stripe-payment`;

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if ((await result).error) {
      console.log(result.error);
    }
  };

  return (
    <>
      {/* <Spinner /> */}
      <Tabs
        activeKey={activeTab}
        // onSelect={(key) => setActiveTab(key)}
        id="justify-tab-example"
        className="mb-3"
        justify
        style={{ fontSize: "20px" }}
      >
        <Tab eventKey="home" style={{ backgroundColor: "white" }}>
          <div className="address-page" style={{ minHeight: "100vh" }}>
            <h2>Delivery Address</h2>
            <div className="previous-addresses">
              {addresses.map((address, index) => (
                <div className="add" key={index}>
                  <div className="addinner">
                    <h3 className="add-title">
                      {address.name} {address.mobileNumber}
                    </h3>
                    <button
                      className="edit-button"
                      onClick={() => {
                        handleShow(address);
                      }}
                    >
                      EDIT
                    </button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>EDIT ADDRESS</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="address-form">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={editAddress.name}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <input
                            type="text"
                            name="mobileNumber"
                            placeholder="10 Digit Mobile Number"
                            value={editAddress.mobileNumber}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                mobileNumber: e.target.value,
                              })
                            }
                            required
                            pattern="\d{10}"
                          />
                          <input
                            type="text"
                            name="pincode"
                            placeholder="Pincode"
                            value={editAddress.pincode}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                pincode: e.target.value,
                              })
                            }
                            required
                          />
                          <input
                            type="text"
                            name="locality"
                            placeholder="Locality"
                            value={editAddress.locality}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                locality: e.target.value,
                              })
                            }
                            required
                          />
                          <textarea
                            name="address"
                            placeholder="Address (Area and Street)"
                            value={editAddress.address}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                address: e.target.value,
                              })
                            }
                            required
                          />
                          <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={editAddress.city}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                city: e.target.value,
                              })
                            }
                            required
                          />
                          <select
                            name="state"
                            value={editAddress.state}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                state: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select State</option>
                            {indianStates.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                          <input
                            type="text"
                            name="landmark"
                            placeholder="Landmark"
                            value={editAddress.landmark}
                            onChange={(e) =>
                              seteditAddress({
                                ...editAddress,
                                landmark: e.target.value,
                              })
                            }
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary" onClick={handleEditSubmit}>
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <p className="add-address">
                    {address.address},{address.locality},{address.landmark},{" "}
                    {address.city} , {address.state}-{address.pincode}
                  </p>
                  <button
                    className="add-button"
                    onClick={() => handleDeliverHereClick(address._id)}
                  >
                    DELIVER HERE
                  </button>
                </div>
              ))}
            </div>
            <div className="mid">
              {" "}
              <button
                className="toggle-form-button"
                onClick={() => setShowForm(!showForm)}
              >
                + {"  "}Add new addresss
              </button>
            </div>

            {showForm && (
              <div>
                <form onSubmit={handleNewAddress} className="address-form">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={newAddress.name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="mobileNumber"
                    placeholder="10 Digit Mobile Number"
                    value={newAddress.mobileNumber}
                    onChange={handleChange}
                    required
                    pattern="\d{10}"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="email"
                    value={newAddress.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={newAddress.pincode}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="locality"
                    placeholder="Locality"
                    value={newAddress.locality}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    name="address"
                    placeholder="Address (Area and Street)"
                    value={newAddress.address}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={newAddress.city}
                    onChange={handleChange}
                    required
                  />
                  <select
                    name="state"
                    value={newAddress.state}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select State</option>
                    {indianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    name="landmark"
                    placeholder="Landmark"
                    value={newAddress.landmark}
                    onChange={handleChange}
                  />
                  <button type="submit">Save and Deliver Here</button>
                  <button
                    type="button"
                    onClick={() =>
                      setNewAddress({
                        name: "",
                        mobileNumber: "",
                        email: "",
                        pincode: "",
                        locality: "",
                        address: "",
                        city: "",
                        state: "",
                        landmark: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </div>
        </Tab>
        <Tab eventKey="summary">
          {/* title="ORDER SUMMARY" */}
          <div className="summary-page" style={{ minHeight: "100vh" }}>
            <div id="head"></div>
            <div className="full-cart">
              <div className="cart">
                <>
                  {cartItems.map((item, index) => (
                    <div key={index} className="cart-item">
                      <img
                        src={item.Product.images[0]}
                        alt={item.Product.title}
                        className="item-image"
                      />
                      <div className="item-details">
                        <h3>{item.Product.title}</h3>
                        <p className="item-description">
                          {item.Product.description}
                        </p>
                        <div className="item-meta">
                          <span className="item-price">
                            &#8377;
                            {item.Product.discountedPrice * item.quantity}
                          </span>
                          <span className="original-price">
                            {item.Product.price * item.quantity}
                          </span>
                          {item.Product.discountPercentage > 0 && (
                            <span className="item-discount">
                              {item.Product.discountPercentage}% Off
                            </span>
                          )}
                        </div>
                        <div className="quantity-controls">
                          <button onClick={() => decrementQuantity(item)}>
                            -
                          </button>
                          <span className="quantity">{item.quantity}</span>
                          <button onClick={() => incrementQuantity(item)}>
                            +
                          </button>
                          <button
                            className="remove-button"
                            onClick={() => removeFromCart(item)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
              <div id="subtotal">
                <h1 className="font font2">PRICE DETAILS</h1>
                <hr />
                <div className="check">
                  <h3 className="font">
                    Price ({cartTotalItems}{" "}
                    {cartTotalItems == 1 ? <>item</> : <>items</>})
                  </h3>
                  <h4 className="right ">&#8377;{cartTotal}</h4>
                </div>
                <div className="check">
                  <h3 className="font">Discount</h3>
                  <h4 className="right " style={{ color: "#28a745" }}>
                    - &#8377;{cartDiscount}
                  </h4>
                </div>
                <div className="check">
                  <h3 className="font">Delivery Charge</h3>
                  <h4 className="fee" style={{ color: "#28a745" }}>
                    Free
                  </h4>
                </div>
                <hr />
                <div className="check">
                  <h2 className="font">Total Payable</h2>
                  <h4 className="right">&#8377;{cartPayable}</h4>
                </div>
                <hr />
                <button id="checkout" onClick={handleOrderCart}>
                  <a style={{ textDecoration: "none", color: "white" }}>
                    Continue
                  </a>
                </button>
              </div>
            </div>
          </div>
        </Tab>
        <Tab eventKey="payment">
          {/* title="PAYMENT" */}
          <div className="payment-confirmation-container"style={{margin:"100px 0px"}}>
            <div className="payment-container">
              <h2>PAYMENT OPTIONS</h2>
              {/* <button onClick={() => handlestripeClick()}>STRIPE CARD</button> */}

              <span className="p">
                <Form.Check
                  aria-label="option 1"
                  onClick={()=>handlePaymentChange("COD")}
                  className="f"
                />
                <p className="cc">CASH ON DELIVERY</p>
              </span>
              <span className="p">
                <Form.Check
                  aria-label="option 1"
                  onClick={()=>handlePaymentChange("CARD")}
                  className="f"
                />
                <p className="cc">STRIPE PAYMENT</p>
              </span>

              {paymentMethod && (
                <>
                  {success && (
                    <Alert variant="outlined" sx={{ marginBottom: "20px" }}>
                      Successful
                    </Alert>
                  )}
                  <div className="card">
                    {/* <CardHeader title="Validate Captcha" /> */}

                    <>
                      <CardActions>
                        <div className="h3">{captcha}</div>

                        <FontAwesomeIcon
                          icon={faArrowsRotate}
                          onClick={() => refreshString()}
                        />
                      </CardActions>

                      <form onSubmit={matchCaptcha}>
                        <input
                          placeholder="Enter captcha here ..."
                          className="capin"
                          value={text}
                          fullwidth="true"
                          onChange={(e) => setText(e.target.value)}
                          // error={valid}
                          helpertext={valid && "Invalid Captcha"}
                        />

                        <Button type="submit">Submit</Button>
                      </form>
                    </>
                  </div>
                  <button
                    className="confirm-order-btn"
                    onClick={handleConfirmOrder}
                  >
                    Confirm Order
                  </button>
                </>
              )}
            </div>
          </div>
        </Tab>
      </Tabs>

    </>
  );
};

export default Checkout;
