import React, { useEffect, useState } from "react";
import "./Cart.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItem,
  fetchCart,
  fetchCartTotal,
  updateCartItemMinus,
  updateCartItemPlus,
} from "../../Store/Features/cartSlice";
import Spinner from "../Spinner/spinner";

const Cart = () => {
  const cartItems = useSelector((state) => state.Cart.items);
  const cartTotal = useSelector((state) => state.Cart.total);
  const cartDiscount = useSelector((state) => state.Cart.discount);
  const cartPayable = useSelector((state) => state.Cart.payable);
  const cartTotalItems = useSelector((state) => state.Cart.totalItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCart());
    dispatch(fetchCartTotal());
  }, [cartItems]);

  const removeFromCart = async (item) => {
    dispatch(deleteCartItem(item));
    dispatch(fetchCart());
    dispatch(fetchCartTotal());
  };

  const incrementQuantity = async (item) => {
    dispatch(updateCartItemPlus(item));
  };

  const decrementQuantity = async (item) => {
    dispatch(updateCartItemMinus(item));
  };

  if (!cartItems.length) {
    return (
      <>
        <Spinner />
        <div
          className="empty-wishlist-container"
          style={{ marginBottom: "100px" }}
        >
          <img
            src="/images/preview.png"
            alt="Empty Wishlist"
            className="wishlist-image"
          />
          <h2>Empty Cart</h2>
          <p>You have no items in your Cart. Start adding!</p>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bth"
          >
            Back To Homepage
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <Spinner />
      <div className="full-cart">
        <div className="cart">
          <>
            {cartItems.map((item, index) => (
              <div key={index} className="cart-item">
                <img
                  src={item.Product.images[0]}
                  alt={item.Product.title}
                  className="item-image"
                />
                <div className="item-details">
                  <h3>{item.Product.title}</h3>
                  <p className="item-description">{item.Product.description}</p>
                  <div className="item-meta">
                    <span className="item-price">
                      &#8377;{item.Product.discountedPrice * item.quantity}
                    </span>
                    <span className="original-price">
                      {item.Product.price * item.quantity}
                    </span>
                    {item.Product.discountPercentage > 0 && (
                      <span className="item-discount">
                        {item.Product.discountPercentage}% Off
                      </span>
                    )}
                  </div>
                  <div className="quantity-controls">
                    <button onClick={() => decrementQuantity(item)}>-</button>
                    <span className="quantity">{item.quantity}</span>
                    <button onClick={() => incrementQuantity(item)}>+</button>
                    <button
                      className="remove-button"
                      onClick={() => removeFromCart(item)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>

        <div id="subtotal">
          <h1 className="font font2">PRICE DETAILS</h1>
          <hr />
          <div className="check">
            <h3 className="font">
              Price ({cartTotalItems}{" "}
              {cartTotalItems === 1 ? <>item</> : <>items</>})
            </h3>
            <h4 className="right ">&#8377;{cartTotal}</h4>
          </div>
          <div className="check">
            <h3 className="font">Discount</h3>
            <h4 className="right " style={{ color: "#28a745" }}>
              - &#8377;{cartDiscount}
            </h4>
          </div>
          <div className="check">
            <h3 className="font">Delivery Charge</h3>
            <h4 className="fee" style={{ color: "#28a745" }}>
              Free
            </h4>
          </div>
          <hr />
          <div className="check">
            <h2 className="font">Total Payable</h2>
            <h4 className="right">&#8377;{cartPayable}</h4>
          </div>
          <hr />
          <button
            id="checkout"
            onClick={() => {
              localStorage.setItem("Checkout", "true");
              navigate("/checkout");
            }}
          >
            <span style={{ textDecoration: "none", color: "white" }}>
              PROCEED TO CHECKOUT
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Cart;
