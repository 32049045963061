import React, { useState } from "react";
import AdminHeader from "../Adminheader/Adminheader";

const Main = () => {
  return <>
  <AdminHeader/>
  <h1>DASHBOARD</h1>
  </>;
};

export default Main;
