import React, { useState, useEffect } from "react";
import "./spinner.css";

const Spinner = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`loader-container ${showLoader ? "" : "hide"}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Spinner;
