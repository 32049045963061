import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const getToken = () => sessionStorage.getItem("Token"); // Function to get the token

export const fetchWishList = createAsyncThunk(
  "wishlist/fetchWishList",
  async (_, { rejectWithValue }) => {
    try {
      let User = localStorage.getItem("sub");
      let url = process.env.REACT_APP_HOST + `wishlist/getWishlist/${User}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToWishList = createAsyncThunk(
  "wishlist/addToWishList",
  async (item, { rejectWithValue }) => {
    try {
      let User = localStorage.getItem("sub");
      let Product = item._id;
      let url = process.env.REACT_APP_HOST + "wishlist/MoveToWishlist";
      const response = await axios.post(
        url,
        {
          User,
          Product,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`, // Include the token in the headers
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWishItem = createAsyncThunk(
  "wishlist/deleteWishItem",
  async (id, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_HOST + `wishlist/deletewishitem/${id}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const wishListSlice = createSlice({
  name: "wishlist",
  initialState: {
    items: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWishList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchWishList.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchWishList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(deleteWishItem.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteWishItem.fulfilled, (state, action) => {
      state.status = "success";
      state.items = state.items.filter(
        (item) => item._id !== action.payload.data._id
      );
    });
    builder.addCase(deleteWishItem.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    builder.addCase(addToWishList.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addToWishList.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(addToWishList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default wishListSlice.reducer;
