import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import {
  fetchAddress,
  addNewAddress,
  EditAddress,
  deleteAddress,
} from "../../Store/Features/addressSlice";
import "./Address.css";
import Spinner from "../Spinner/spinner";

const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

const Address = () => {
  const addresses = useSelector((state) => state.Address.addresses);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editId, seteditId] = useState("");

  const [newAddress, setNewAddress] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    landmark: "",
    email: "",
    User: localStorage.getItem("sub"),
  });

  const [editAddressData, seteditAddressData] = useState({
    name: "",
    mobileNumber: "",
    pincode: "",
    locality: "",
    address: "",
    city: "",
    state: "",
    landmark: "",
  });
  
  const dispatch = useDispatch();

  const handleShow = (address) => {
    seteditId(address._id);
    seteditAddressData({ ...address });
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleClose2 = () => setShow2(false);

  useEffect(() => {
    dispatch(fetchAddress());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    dispatch(EditAddress({ editId, editAddress: editAddressData }))
      .unwrap()
      .then(() => {
        toast.success("Address edited successfully!");
      })
      .catch(() => {
        toast.error("Error editing address.");
      });
  };

  const handleNewAddress = (e) => {
    e.preventDefault();
    setShowForm(!showForm);
    setShow2(false);
    dispatch(addNewAddress(newAddress))
      .unwrap()
      .then(() => {
        toast.success("Address added successfully!");
      })
      .catch(() => {
        toast.error("Error adding address.");
      });
  };

  const handleDelete = (id) => {
    dispatch(deleteAddress(id))
      .unwrap()
      .then(() => {
        toast.success("Address deleted successfully!");
      })
      .catch(() => {
        toast.error("Error deleting address.");
      });
  };

  return (
    <div>
      <Spinner />
      <div className="address-container">
        <h5>Account</h5>
        <hr />
        <div className="address-header">
          <h5 style={{ float: "left" }} className="sd">
            Saved Addresses
          </h5>
          <button
            className="add-address-btn"
            style={{ float: "right", background: "none" }}
            onClick={() => setShow2(true)}
          >
            + Add New Address
          </button>
        </div>

        <div className="address-list">
          {addresses.map((address, index) => (
            <div className="address-card" key={index}>
              <div className="address-content">
                <h3 className="address-name">{address.name}</h3>
                <p className="address-details2">
                  {address.address}, {address.landmark}
                </p>
                <p className="address-details2">{address.city}</p>
                <p className="address-details2">
                  {address.locality} - {address.pincode}
                </p>

                <p
                  className="address-details2"
                  style={{ textTransform: "uppercase" }}
                >
                  {address.state}
                </p>

                <p className="address-mobile" style={{ margin: "10px 0px" }}>
                  Mobile: {address.mobileNumber}
                </p>
                <div className="address-actions">
                  <button
                    className="edit-btn2"
                    style={{
                      width: "50%",
                      borderRight: " 0.2px solid rgb(66, 64, 64)",
                    }}
                    onClick={() => {
                      handleShow(address);
                    }}
                  >
                    Edit
                  </button>

                  <button
                    className="remove-btn2"
                    style={{ width: "50%" }}
                    onClick={() => {
                      handleDelete(address._id);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>EDIT ADDRESS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom-address-form">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={editAddressData.name}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  name: e.target.value,
                })
              }
              required
              className="custom-input"
            />
            <input
              type="text"
              name="mobileNumber"
              placeholder="10 Digit Mobile Number"
              value={editAddressData.mobileNumber}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  mobileNumber: e.target.value,
                })
              }
              required
              pattern="\d{10}"
              className="custom-input"
            />
            <textarea
              name="address"
              placeholder="Address (Area and Street)"
              value={editAddressData.address}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  address: e.target.value,
                })
              }
              required
              className="custom-textarea"
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={editAddressData.city}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  city: e.target.value,
                })
              }
              required
              className="custom-input"
            />
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              value={editAddressData.pincode}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  pincode: e.target.value,
                })
              }
              required
              className="custom-input"
            />
            <input
              type="text"
              name="locality"
              placeholder="Locality"
              value={editAddressData.locality}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  locality: e.target.value,
                })
              }
              required
              className="custom-input"
            />
            <select
              name="state"
              value={editAddressData.state}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  state: e.target.value,
                })
              }
              required
              className="custom-select"
            >
              <option value="">Select State</option>
              {indianStates.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="landmark"
              placeholder="Landmark"
              value={editAddressData.landmark}
              onChange={(e) =>
                seteditAddressData({
                  ...editAddressData,
                  landmark: e.target.value,
                })
              }
              className="custom-input"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>ADD ADDRESS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="new-address-form" style={{ margin: "15px" }}>
            <form onSubmit={handleNewAddress}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={newAddress.name}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="mobileNumber"
                placeholder="10 Digit Mobile Number"
                value={newAddress.mobileNumber}
                onChange={handleChange}
                required
                pattern="\d{10}"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={newAddress.email}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="pincode"
                placeholder="Pincode"
                value={newAddress.pincode}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="locality"
                placeholder="Locality"
                value={newAddress.locality}
                onChange={handleChange}
                required
              />
              <textarea
                name="address"
                placeholder="Address (Area and Street)"
                value={newAddress.address}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="city"
                placeholder="City"
                value={newAddress.city}
                onChange={handleChange}
                required
              />
              <select
                name="state"
                value={newAddress.state}
                onChange={handleChange}
                required
              >
                <option value="">Select state</option>
                {indianStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="landmark"
                placeholder="Landmark"
                value={newAddress.landmark}
                onChange={handleChange}
              />

              <div style={{ marginTop: "10px" }}>
                <Button variant="secondary" onClick={handleClose2}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Add Address
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Address;
