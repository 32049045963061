import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const getToken = () => sessionStorage.getItem("Token"); // Function to get the token

export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, { rejectWithValue }) => {
    try {
      let User = localStorage.getItem("sub");
      let url = process.env.REACT_APP_HOST + `cart/getcartitems/${User}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCartTotal = createAsyncThunk(
  "cart/fetchCartTotal",
  async (_, { rejectWithValue }) => {
    try {
      let User = localStorage.getItem("sub");
      let url = process.env.REACT_APP_HOST + `cart/getcarttotal/${User}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (item, { rejectWithValue }) => {
    try {
      let User = localStorage.getItem("sub");
      let ProductId = item._id;
      let url = process.env.REACT_APP_HOST + "cart/AddToCart";
      const response = await axios.post(
        url,
        {
          User,
          ProductId,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`, // Include the token in the headers
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCartItemPlus = createAsyncThunk(
  "cart/updateCartItemPlus",
  async (item, { rejectWithValue }) => {
    try {
      let url =
        process.env.REACT_APP_HOST + `cart/updatecartitem/add/${item._id}`;
      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCartItemMinus = createAsyncThunk(
  "cart/updateCartItemMinus",
  async (item, { rejectWithValue }) => {
    try {
      let url =
        process.env.REACT_APP_HOST + `cart/updatecartitem/minus/${item._id}`;
      const response = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      }); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCartItem = createAsyncThunk(
  "cart/deleteCartItem",
  async (item, { rejectWithValue }) => {
    try {
      let url = process.env.REACT_APP_HOST + `cart/deletecartitem/${item._id}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${getToken()}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    total: 0,
    discount: 0,
    payable: 0,
    totalItems: 0,
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCart.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      state.items = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(fetchCartTotal.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCartTotal.fulfilled, (state, action) => {
      state.total = action.payload.total;
      state.discount = action.payload.discount;
      state.payable = action.payload.payable;
      state.totalItems = action.payload.totalItems;
      state.status = "success";
    });
    builder.addCase(fetchCartTotal.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(addToCart.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addToCart.fulfilled, (state) => {
      state.status = "success";
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(updateCartItemPlus.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateCartItemPlus.fulfilled, (state, action) => {
      const updatedItemIndex = state.items.findIndex(
        (item) => item._id === action.meta.arg._id
      );
      if (updatedItemIndex >= 0) {
        state.items[updatedItemIndex] = {
          ...state.items[updatedItemIndex],
          quantity: state.items[updatedItemIndex].quantity + 1,
        };
      }
      state.status = "success";
    });
    builder.addCase(updateCartItemPlus.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(updateCartItemMinus.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateCartItemMinus.fulfilled, (state, action) => {
      const updatedItemIndex = state.items.findIndex(
        (item) => item._id === action.meta.arg._id
      );
      if (updatedItemIndex >= 0) {
        state.items[updatedItemIndex] = {
          ...state.items[updatedItemIndex],
          quantity: state.items[updatedItemIndex].quantity - 1,
        };
      }
      state.status = "success";
    });
    builder.addCase(updateCartItemMinus.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(deleteCartItem.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteCartItem.fulfilled, (state, action) => {
      state.items = state.items.filter(
        (item) => item._id !== action.meta.arg._id
      );
      state.status = "success";
    });
    builder.addCase(deleteCartItem.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default cartSlice.reducer;
