import React, { useEffect, useState } from "react";
import "./track.css"; // Importing the CSS file for styling
import axios from "axios";

const steps = [
  "Order Placed",
  "Order Processing",
  "Order Confirmed",
  "Order Packed in Dispatch House",
  "Shipped to Delivery Center",
  "Arrived at Nearest Delivery Facility",
  "Out for Delivery",
  "Order Delivered",
];

const Tracker = (status) => {
  const [trackingDetails, setTrackingDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Status, setStatus] = useState(status)

  const getStepStatus = (index) => {
    return index <= steps.indexOf(status.status) ? "completed" : "pending";
  };

  const trackOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://pre-alpha.ithinklogistics.com/api_v3/order/track.json",
        {
          data: {
            awb_number_list: status.waybill,
            access_token: "5a7b40197cd919337501dd6e9a3aad9a",
            secret_key: "2b54c373427be180d1899400eeb21aab",
          },
        }, // You need to pass an empty object or the actual data payload here
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const scanDetails = response.data.data["901234567109"].scan_details;
      setTrackingDetails(scanDetails);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    trackOrder();
  }, []);

  const handleCancel = async (orderId) => {
    try {
      let url = process.env.REACT_APP_HOST + `order/cancelOrder/${orderId}`;
      const response = await axios.patch(url);
      // alert("Order cancelled successfully!");
      setStatus("Cancelled"); // Update status locally
    } catch (error) {
      // alert(`Failed to cancel order: ${error.response.data.message}`);
    }
  };

  if (!status.waybill) {
    return (
      <>
        <p className="order-tracking-message">
          Order Tracking will be provided soon
        </p>
      </>
    );
  }

  return (
    <>
      <div className="track-order-container">
        {trackingDetails.map((detail, index) => (
          <section className="timeline-area" key={index}>
            <div className="timeline-con">
              <h4 className="time-h2">
                {detail.status} &nbsp;&nbsp;
                {detail.scan_date_time.split(" ").map((item, index) => (
                  <span key={index}>[ {item} ]&nbsp;&nbsp;</span>
                ))}
                &nbsp;&nbsp;
              </h4>
              <h5 className="time-h3">{detail.scan_location}</h5>
              <h5 className="time-h4">{detail.remark}</h5>
            </div>
          </section>
        ))}
        {/* {status === "Ordered" ? (
          <button onClick={handleCancel(order._id)}>CANCEL</button>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};

export default Tracker;
