import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch all addresses for the user
export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async (_, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("Token"); // Get the token from session storage
      let User = localStorage.getItem("sub");
      let url = process.env.REACT_APP_HOST + `address/getAddress/${User}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add a new address
export const addNewAddress = createAsyncThunk(
  "address/addNewAddress",
  async (newAddress, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("Token"); // Get the token from session storage
      let url = process.env.REACT_APP_HOST + "address/createAddress";
      const response = await axios.post(url, newAddress, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit an existing address
export const EditAddress = createAsyncThunk(
  "address/editAddress",
  async ({ editId, editAddress }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("Token"); // Get the token from session storage
      let url = process.env.REACT_APP_HOST + `address/update/${editId}`;
      const response = await axios.put(url, editAddress, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete an address
export const deleteAddress = createAsyncThunk(
  "address/deleteAddress",
  async (id, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("Token"); // Get the token from session storage
      let url = process.env.REACT_APP_HOST + `address/delete/${id}`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState: {
    addresses: [],
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAddress.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.addresses = action.payload;
      state.status = "success";
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(addNewAddress.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addNewAddress.fulfilled, (state, action) => {
      state.addresses.push(action.payload);
      state.status = "success";
    });
    builder.addCase(addNewAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(EditAddress.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(EditAddress.fulfilled, (state, action) => {
      const index = state.addresses.findIndex(
        (address) => address._id === action.meta.arg.editId
      );
      if (index !== -1) {
        state.addresses[index] = action.payload;
      }
      state.status = "success";
    });
    builder.addCase(EditAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(deleteAddress.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteAddress.fulfilled, (state, action) => {
      state.addresses = state.addresses.filter(
        (address) => address._id !== action.meta.arg
      );
      state.status = "success";
    });
    builder.addCase(deleteAddress.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
  },
});

export default addressSlice.reducer;
