

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import toast from "react-hot-toast";
import { Form, Button, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function VendorLoginForm() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_HOST}vendor/loginvendor`;
    try {
      const response = await axios.post(url, loginData);
      localStorage.setItem("vendorID", response.data.vendor);
      toast.success("Login successful!");
      navigate("/vendorProfile");
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="back2">
        <div className="login-leftt">
         <img src="./images/lg.jpg" alt="...Loading" className="lgimg"/>
        </div>
        <div className="rvendor-form-wrapper">
          <h2 className="vendor-form-title">LOGIN</h2>
          <Form onSubmit={handleLogin} className="vendor-form-content">
            <Form.Group controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={loginData.email}
                onChange={handleInputChange}
                style={{ width: "400px" }}
                required
              />
            </Form.Group>

            <Form.Group controlId="formGridPassword">
              <Form.Label>Password</Form.Label>
              <div className="password-wrapper">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  value={loginData.password}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="button"
                  className="eye-button"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </Form.Group>

            <div className="btn-wrapper">
              <Button
                className="vendor-form-button"
                type="submit"
                style={{ width: "200px", margin: "20px" }}
              >
                Login
              </Button>
              <h5>
                Don't have an Account?{" "}
                <span
                  className="sss"
                  onClick={() => {
                    navigate("/vendorForm");
                  }}
                >
                  Sign Up
                </span>
              </h5>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VendorLoginForm;
