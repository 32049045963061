import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import Spinner from "./components/Spinner/spinner";
import Layout from "./Utils/Layout";
import PrivateRoutes from "./Utils/privateRoute";
import { useEffect, useState } from "react";
import ReviewForm from "./components/Review/Review";
import Wishlist from "./components/Wishlist/wishlist";
import Home from "./components/Home/Home";
import AddProduct from "./Admin/Products/Addproduct";
import Checkout from "./components/Checkout/checkout";
import Cart from "./components/Cart/Cart";
import Order from "./components/Order/order";
import Main from "./Admin/Main/main";
import Adminproduct from "./Admin/Products/Adminproduct";
import Profile from "./components/Profile/Profile";
import AdminOrder from "./Admin/Orders/Adminorders";
import VendorList from "./Admin/Vendor/vendor";
import VendorForm from "./Admin/Vendor/vendorform";
import VendorDashboard from "./Admin/Vendor/vendordash";
import Tracker from "./components/tracker/tracker";
import AddVendorProduct from "./Admin/Vendor/Addvendorproduct";
import BecomeASeller from "./components/seller/seller";
import VendorLoginForm from "./Admin/Vendor/vendorlogin";
import Completeorder from "./Admin/Orders/completeorder";
import IthinkOrders from "./Admin/Orders/IthinkOrders";
import Address from "./components/Address/Address";
import Product from "./components/Product/Product";
import Subcategoires from "./components/Subcategories/Subcategoires";
import Failure from "./components/Checkout/failure";
import Success from "./components/Checkout/success";
import Card from "./components/Card/Card";
import PageNotfound from "./components/PageNotFound/PageNotfound";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BrowserRouter>
      <div className="app">
        <main id="main">
          <Spinner />
          <Toaster
            position={isMobile ? "bottom-center" : "top-center"}
            reverseOrder={false}
          />
          <Routes>
            {/* Public Routes with Layout */}
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/card" element={<Card />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/address" element={<Address />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/orders" element={<Order />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/subcategoires" element={<Subcategoires />} />
              <Route path="/product/:Product_id" element={<Product />} />
              <Route path="/reviewProduct" element={<ReviewForm />} />
              {/* Private Routes Nested Under Layout */}
              <Route element={<PrivateRoutes />}>
                <Route path="/checkout" element={<Checkout />} />
                {/* Add more private routes here if needed */}
              </Route>
            </Route>

            {/* Public Routes without Layout */}
            <Route path="/vendorlogin" element={<VendorLoginForm />} />
            {/* Add more routes that don't use Layout here */}

            {/* Admin and Vendor Routes (Assuming they don't need Layout) */}
            <Route path="/addproduct" element={<AddProduct />} />
            <Route path="/admin" element={<Main />} />
            <Route path="/adminproduct" element={<Adminproduct />} />
            <Route path="/adminorders" element={<AdminOrder />} />
            <Route path="/vendor" element={<VendorList />} />
            <Route path="/vendorform" element={<VendorForm />} />
            <Route path="/vendorprofile" element={<VendorDashboard />} />
            <Route path="/tracker" element={<Tracker />} />
            <Route path="/addvendorproduct" element={<AddVendorProduct />} />
            <Route path="/becomeaseller" element={<BecomeASeller />} />
            <Route path="/completeorder" element={<Completeorder />} />
            <Route path="/ithinkorders" element={<IthinkOrders />} />
            <Route path="/failure" element={<Failure />} />
            <Route path="/success" element={<Success />} />

            {/* Catch-all Route */}
            <Route path="*" element={<PageNotfound />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
