import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Product.css";
import "./../Card/Card.css";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { RiShoppingBagLine } from "react-icons/ri";
import { addToWishList } from "../../Store/Features/wishlistSlice";
import { useDispatch } from "react-redux";
import { addToCart } from "../../Store/Features/cartSlice";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../Spinner/spinner";

const Product = () => {
  const [item, setItem] = useState({});
  const [images, setImages] = useState([]);
  const [isWishlist, setIsWishlist] = useState(false);
  const [AddtoWishlist, setAddedToWishlist] = useState(false);
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [totalReviews, setTotalReviews] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [ratingPercentages, setRatingPercentages] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  
  const [id, setId] = useState(
    params.Product_id || localStorage.getItem("product")
  );

  const handleAddToCart = async (item) => {
    let User = localStorage.getItem("sub");
    if (!User) {
      toast.error("please do login");
      return;
    }
    toast.loading("...Loading");
    dispatch(addToCart(item)).then(() => {
      toast.dismiss();
      toast.success("Item moved to Bag");
    });
  };

  const fetchProducts = async (sport) => {
    let url = process.env.REACT_APP_HOST + `product/getSportproducts/${sport}`;
    try {
      const response = await axios.get(url);
      setProducts(response.data.filter((item) => item._id !== id));
    } catch (err) {
      console.log("Failed to fetch products");
    }
  };

  const calculateReviewStatistics = (reviews) => {
    const total = reviews.length;
    const sumOfRatings = reviews.reduce(
      (acc, review) => acc + review.rating,
      0
    );
    const average = sumOfRatings / total;

    const ratingCount = {
      5: reviews.filter((r) => r.rating === 5).length,
      4: reviews.filter((r) => r.rating === 4).length,
      3: reviews.filter((r) => r.rating === 3).length,
      2: reviews.filter((r) => r.rating === 2).length,
      1: reviews.filter((r) => r.rating === 1).length,
    };

    const percentages = {
      5: ((ratingCount[5] / total) * 100).toFixed(1),
      4: ((ratingCount[4] / total) * 100).toFixed(1),
      3: ((ratingCount[3] / total) * 100).toFixed(1),
      2: ((ratingCount[2] / total) * 100).toFixed(1),
      1: ((ratingCount[1] / total) * 100).toFixed(1),
    };

    setTotalReviews(total);
    setAverageRating(average.toFixed(1));
    setRatingPercentages(percentages);
  };

  useEffect(() => {
    let User = localStorage.getItem("sub");

    let url =
      process.env.REACT_APP_HOST + `product/getproductbyid/${id}/${User}`;

    axios
      .get(url)
      .then((res) => {
        setItem(res.data.product);
        fetchProducts(res.data.product.category);
        setIsWishlist(res.data.wish);
        setImages([...res.data.product.images]);

        if (res.data.product.reviews && res.data.product.reviews.length > 0) {
          calculateReviewStatistics(res.data.product.reviews);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [id]);

  const onAddToWishlist = async () => {
    let User = localStorage.getItem("sub");
    if (!User) {
      toast.error("please do login");
      return;
    }
    setAddedToWishlist(true);
    dispatch(addToWishList(item))
      .then((res) => {
        toast.success(res.payload.message);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleShowModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Spinner />
      <div className="main-layout">
        <div className="image-container2">
          {images.map((img, index) => (
            <div
              key={index}
              className="image-box"
              onClick={() => handleShowModal(img)}
            >
              <img src={img} alt="Product" className="simg" />
            </div>
          ))}
        </div>
        <div className="details-container">
          <div className="product-details">
            <p className="product-brand">{item.brand}</p>
            <p className="product-title">{item.title}</p>
            <hr />
            <p className="product-description">
              <strong>Description:</strong> {item.description}
            </p>
            <p className="product-price">
              <span className="pp1"> &#8377;{item.discountedPrice}</span>
              <span className="pp2">MRP</span>
              <span className="pp3">&#8377;{item.price}</span>
              <span className="pp4">({item.discountPercentage}% OFF)</span>
              <br />
              <span className="pp5">inclusive of all taxes</span>
            </p>
            <div className="btn-grp">
              {isWishlist || AddtoWishlist ? (
                <button className="atg1 more" onClick={onAddToWishlist}>
                  <span style={{ marginRight: "8px" }}>
                    <FaHeart
                      style={{
                        fontSize: "24px",
                        marginBottom: "2px",
                        color: "rgb(244, 65, 121)",
                      }}
                    />
                  </span>{" "}
                  WISHLISTED
                </button>
              ) : (
                <button
                  className="atg1"
                  onClick={onAddToWishlist}
                  style={{ padding: "20px 60px", backgroundColor: "white" }}
                >
                  <span style={{ marginRight: "8px" }}>
                    <FaRegHeart
                      style={{ fontSize: "24px", marginBottom: "2px" }}
                    />
                  </span>{" "}
                  WISHLIST
                </button>
              )}
              <button className="atg" onClick={() => handleAddToCart(item)}>
                <span style={{ marginRight: "8px" }}>
                  <RiShoppingBagLine
                    style={{ fontSize: "24px", marginBottom: "2px" }}
                  />
                </span>{" "}
                ADD TO BAG
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="home">
        {products &&
          products.map((item, index) => {
            return (
              <div key={index}>
                <div
                  className="product-card"
                  onClick={() => {
                    localStorage.setItem("product", item._id);
                    navigate(`/product/${item._id}`);
                    window.location.reload();
                  }}
                >
                  <div className="product-image">
                    <img
                      src={item.images[0]}
                      className="courousel courimg"
                      alt="Loading..."
                      loading="lazy"
                    />
                  </div>
                  <div className="item-det">
                    <div className="item-mid">
                      <p className="brand">{item.brand}</p>
                    </div>
                    <p className="itemtitle">{item.title}</p>
                    <div className="price-det">
                      <p className="itemdisprice">Rs.{item.discountedPrice}</p>
                      <p className="itemprice">Rs.{item.price}</p>
                      <p className="itemdiscount">
                        ({item.discountPercentage}% OFF)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <hr />
      <div className="review-section">
        <div className="review-header">
          <h4 className="review-title">Review this product</h4>
          <h5 className="review-subtitle">
            Share your thoughts with other customers
          </h5>
          <button
            className="review-button"
            onClick={() => navigate("/reviewProduct")}
          >
            Write a product review
          </button>
        </div>
      </div>
      <div className="reviews-section">
        <br />
        {item?.reviews?.length > 0 ? (
          <div className="reviews-list">
            <div className="no-review">
              <h3 className="CR-Title">Customer Reviews</h3>
            </div>
            <ReviewBreakdown
              totalReviews={totalReviews}
              averageRating={averageRating}
              ratingPercentages={ratingPercentages}
            />
            {item.reviews.map((review, index) => (
              <div key={index} className="review-card">
                <div className="reviews-header">
                  <img
                    src={review.user.picture}
                    alt={review.user.name}
                    className="user-avatar"
                  />
                  <div className="user-info">
                    <p className="user-name">{review.user.name}</p>
                  </div>
                </div>
                <div className="review-body">
                  <p className="review-date">
                    Reviewed in india on{" "}
                    {new Date(review.createdAt).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <StarRating rating={review.rating} />
                  <span className="verified-purchase">Verified Purchase</span>
                  <h4 className="reviews-title">{review.title}</h4>
                  <p className="review-comment">{review.comment}</p>
                  {review.image && (
                    <div className="review-image-container">
                      {review.image.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`Review Image ${index + 1}`}
                          className="review-image"
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-review">
            <p className="no-reviewP">No reviews yet for this product.</p>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Product Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content">
            <div className="modal-image-display">
              <div className="modal-image-gallery">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt="Product"
                    className="modal-thumbnail"
                    onClick={() => setSelectedImage(img)}
                  />
                ))}
              </div>
              <img
                src={selectedImage}
                alt="Product"
                className="modal-main-image"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Product;

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const halfStar = rating % 1 !== 0; // If there's a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Number of empty stars

  return (
    <div className="star-rating">
      {Array(fullStars)
        .fill(0)
        .map((_, index) => (
          <span key={index}>&#9733;</span> // Full star
        ))}
      {halfStar && <span>&#9733;</span>}{" "}
      {Array(emptyStars)
        .fill(0)
        .map((_, index) => (
          <span key={index}>&#9734;</span> // Empty star
        ))}
    </div>
  );
};

const ReviewBreakdown = ({
  totalReviews,
  averageRating,
  ratingPercentages,
}) => {
  const sortedRatings = Object.keys(ratingPercentages)
    .sort((a, b) => b - a);

  return (
    <div className="review-breakdown">
      <div className="review-summary">
        <div className="average-rating">
          <span className="stars">★</span>
          <span className="average">{averageRating}</span> out of 5
        </div>
        <p className="total-reviews">{totalReviews} global ratings</p>
      </div>
      <div className="ratings-breakdown">
        {sortedRatings.map((rating, index) => (
          <div className="rating-bar" key={index}>
            <span>{rating} star</span>
            <div className="bar-container">
              <div
                className="filled-bar"
                style={{ width: `${ratingPercentages[rating]}%` }}
              ></div>
            </div>
            <span className="percentage">{ratingPercentages[rating]}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};