import React, { useContext, useEffect, useState } from "react";
import {
  faMagnifyingGlass,
  faArrowLeft,
  faCircleUp,
  faTruckFast,
  faHeart,
  faCartShopping,
  faLocationDot,
  faUserPen,
  faRightFromBracket,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FaSearch,
  FaUserCircle,
  FaAngleDown,
  FaAngleRight,
} from "react-icons/fa";
import AuthContext from "../../context/Auth/AuthContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BsShop } from "react-icons/bs";
import { MdOutlineAccountCircle } from "react-icons/md";
import { RiShoppingBagLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { setSubCategory } from "../../Store/Features/subcategoryslice";
import axios from "axios";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const sportsCategories = {
  Cricket: [
    "Cricket Bats",
    "Batting Gloves",
    "Batting Pads",
    "Cricket Bags",
    "Cricket Helmets",
    "Cricket Protective",
    "Wicket Keeping",
    "Cricket Balls",
    "Cricket Accessories",
    "Cricket Shoes",
    "Cricket Clothing",
    "Cricket Supporter Wear",
    "Others",
  ],
  Football: [
    "Football Boots",
    "Football Jerseys",
    "Football Shorts",
    "Football Socks",
    "Goalkeeper Gloves",
    "Football Balls",
    "Training Equipment",
    "Football Accessories",
    "Others",
  ],
  Badminton: [
    "Badminton Racquets",
    "Badminton Accessories",
    "Squash Racquets",
    "Squash Accessories",
    "Court Shoes",
    "Others",
  ],
  Tennis: [
    "Adult Tennis Racquets",
    "Junior Tennis Racquets",
    "Tennis Balls",
    "Tennis Bags",
    "Tennis Accessories",
    "Tennis Clothing",
    "Tennis Shoes",
    "Pickleball",
    "Others",
  ],
  Hockey: [
    "Hockey Sticks",
    "Hockey Balls",
    "Hockey Bags",
    "Hockey Protective & Accessories",
    "Hockey Shoes",
    "Others",
  ],
  Othersports: [
    "Squash Rackets",
    "Boxing, Karate, Wrestling",
    "Chess",
    "Swimming",
    "Pickleball Paddle",
    "Others",
  ],
};
const HomeNavbar = () => {
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const [item, setitem] = useState("");
  const [show, setShow] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  const toggleFormVisibility = () => {
    setIsVisible(!isVisible);
  };

  const mobilesearch = (e) => {
    handlesubmit(e);
    setIsVisible(!isVisible);
  };

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
    document.querySelectorAll(".category-header").forEach((header) => {
      if (header.textContent.includes(category)) {
        header.classList.add("wave");
        setTimeout(() => header.classList.remove("wave"), 400); // Remove the class after animation
      }
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Global = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error("Error during login redirect:", error);
    }
  };

  const GetToken = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_HOST + "GenToken",
        user
      );
      //console.log(response.data.token);
      sessionStorage.setItem("Token", response.data.token);
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    const saveUserToDB = async () => {
      if (isAuthenticated && user) {
        try {
          // Send user data to backend
          let Url = `${process.env.REACT_APP_HOST}user/saveUser`;
         let response =  await axios.post(Url, {
            email: user.email,
            email_verified: user.email_verified,
            family_name: user.family_name || null,
            given_name: user.given_name || null,
            name: user.name,
            nickname: user.nickname,
            picture: user.picture,
            sub: user.sub,
          });
          sessionStorage.setItem("User_Id", response.data.User._id);
        } catch (error) {
          console.error("Error saving user to the database:", error);
        }
      }
    };

    saveUserToDB();
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem("sub", user.sub);
      if (!sessionStorage.getItem("Token")) {
        GetToken();
      }
    }
  }, [user]);

  // console.log(user);
  const handleLogout = () => {
    return new Promise((resolve, reject) => {
      try {
        logout({
          returnTo: window.location.origin,
        });
        localStorage.removeItem("sub");
        sessionStorage.removeItem("Token");
        resolve("Logged out successfully");
      } catch (error) {
        reject("Error during logout: " + error);
      }
    });
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    Global.setsearch(item);
  };

  const handleSubcategory = (item) => {
    localStorage.setItem("subcat", item);
    dispatch(setSubCategory(item));
    navigate("/subcategoires");
  };

  return (
    <nav>
      <>
        <div className="bars" onClick={handleShow}>
          <FontAwesomeIcon icon={faBars} style={{ fontSize: "25px" }} />
        </div>
        <Offcanvas show={show} onHide={handleClose} style={{ width: "300px" }}>
          <Offcanvas.Header>
            <Offcanvas.Title>
              {" "}
              <div className="responsive-header">
                <FaUserCircle size={30} className="iconoc" />
                {!isAuthenticated ? (
                  <div
                    className="login-signup"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    Login & Signup
                  </div>
                ) : (
                  <div className="login-signup">Welcome User</div>
                )}

                <img src="images/SM.png" alt="Logo" className="smlogo" />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{ borderTop: "2px solid black", padding: "0px" }}
          >
            <div className="off-canva">
              <div className="categories">
                {Object.keys(sportsCategories).map((category) => (
                  <div key={category} className="category">
                    <div
                      className="category-header"
                      onClick={() => toggleCategory(category)}
                    >
                      {category}
                      {activeCategory === category ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleRight />
                      )}
                    </div>
                    {activeCategory === category && (
                      <ul className="subcategory-list">
                        {sportsCategories[category].map((subcategory) => (
                          <li
                            key={subcategory}
                            className="subcategory"
                            onClick={() => {
                              handleSubcategory(subcategory);
                              setShow(false);
                            }}
                          >
                            {subcategory}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
              <hr />
              <ul className="phone-menu">
                <li
                  onClick={() => {
                    isAuthenticated ? navigate("/orders") : handleLogin();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTruckFast}
                    style={{ marginRight: "15px" }}
                  />
                  Orders
                </li>
                <li
                  onClick={() => {
                    isAuthenticated ? navigate("/wishlist") : handleLogin();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{ marginRight: "20px" }}
                  />
                  Wishlist
                </li>
                <li
                  onClick={() => {
                    isAuthenticated ? navigate("/cart") : handleLogin();
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    style={{ marginRight: "19px" }}
                  />
                  Bag
                </li>
                <li
                  onClick={() => {
                    isAuthenticated ? navigate("/Address") : handleLogin();
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ marginRight: "25px" }}
                  />
                  Saved Addresses
                </li>

                {isAuthenticated ? (
                  <>
                    <li onClick={() => navigate("/profile")}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faUserPen}
                        style={{ marginRight: "18px" }}
                      />
                      Edit Profile
                    </li>{" "}
                    <li onClick={() => handleLogout()}>
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        style={{ marginRight: "22px" }}
                      />
                      Logout
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
      <Link to="/" className="title">
        <img src="/images/logo.png" alt="...loading" id="log" />
      </Link>
      <div className="navs">
        <div className="nav-child cricket">
          CRICKET
          <div className="submenu sbc">
            <ul className="sm">
              {[
                "Cricket Bats",
                "Batting Gloves",
                "Batting Pads",
                "Cricket Bags",
                "Cricket Helmets",
                "Cricket Protective",
                "Wicket Keeping",
                "Cricket Balls",
                "Cricket Accessories",
                "Cricket Shoes",
                "Cricket Clothing",
                "Cricket Supporter Wear",
                "Cricket Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav-child football">
          FOOTBALL
          <div className="submenu sbf">
            <ul className="sm">
              {[
                "Football Boots",
                "Football Jerseys",
                "Football Shorts",
                "Football Socks",
                "Goalkeeper Gloves",
                "Football Balls",
                "Training Equipment",
                "Football Accessories",
                "Football Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav-child badminton">
          BADMINTON
          <div className="submenu sbb">
            <ul className="sm">
              {[
                "Badminton Racquets",
                "Badminton Accessories",
                "Squash Racquets",
                "Squash Accessories",
                "Court Shoes",
                "Badminton Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav-child tennis">
          TENNIS
          <div className="submenu sbt">
            <ul className="sm">
              {[
                "Adult Tennis Racquets",
                "Junior Tennis Racquets",
                "Tennis Balls",
                "Tennis Bags",
                "Tennis Accessories",
                "Tennis Clothing",
                "Tennis Shoes",
                "Pickleball",
                "Tennis Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav-child hockey">
          HOCKEY
          <div className="submenu sbh">
            <ul className="sm">
              {[
                "Hockey Sticks",
                "Hockey Balls",
                "Hockey Bags",
                "Hockey Protective & Accessories",
                "Hockey Shoes",
                "Hockey Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="nav-child other">
          OTHER
          <div className="submenu sbo">
            <ul className="sm">
              {[
                "Squash Rackets",
                "Boxing, Karate, Wrestling",
                "Chess",
                "Swimming",
                "Pickleball Paddle",
                "Others",
              ].map((item) => (
                <li key={item} onClick={() => handleSubcategory(item)}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <form onSubmit={handlesubmit} className="seaform">
        <div className="search-container">
          <button type="submit" className="search-button" aria-label="Search">
            <FaSearch />
          </button>
          <input
            type="text"
            placeholder="Search for Products , Brands and More"
            className="search-input ins clr"
            value={item}
            onChange={(e) => {
              setitem(e.target.value);
            }}
          />
        </div>
      </form>
      <div className="navbar-main">
        <button className="short">
          <FontAwesomeIcon
            className="sizz"
            icon={faMagnifyingGlass}
            onClick={toggleFormVisibility}
          />
        </button>
        {isVisible && (
          <>
            <div className="search-panel">
              <button className="short">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="arrow"
                  onClick={toggleFormVisibility}
                />
              </button>

              <input
                type="text"
                placeholder="Search for brands and products..."
                className="search-field clr"
                onChange={(e) => {
                  setitem(e.target.value);
                }}
              />
              <button className="short">
                <FontAwesomeIcon
                  className="sizz"
                  icon={faCircleUp}
                  onClick={mobilesearch}
                />
              </button>
            </div>
          </>
        )}

        <div className="nav-button">
          <MdOutlineAccountCircle style={{ fontSize: "20px" }} />
          <span className="button-text">Profile</span>
          <div className="profile-menu">
            <h5 className="wc">Welcome !</h5>
            {!isAuthenticated ? (
              <p className="wc2">Manage account and orders</p>
            ) : (
              <p className="wc2">
                Happy to see you{" "}
                <span>
                  <SentimentSatisfiedAltIcon />
                </span>
              </p>
            )}
            {!isAuthenticated ? (
              <button
                className="login-signup-button"
                onClick={() => handleLogin()}
              >
                LOGIN / SIGNUP
              </button>
            ) : (
              <></>
            )}

            <hr style={{ marginBottom: "5px" }} />
            <ul className="profile-menu-list">
              <li
                onClick={() => {
                  isAuthenticated ? navigate("/orders") : handleLogin();
                }}
              >
                <FontAwesomeIcon
                  icon={faTruckFast}
                  style={{ marginRight: "15px" }}
                />
                Orders
              </li>
              <li
                onClick={() => {
                  isAuthenticated ? navigate("/wishlist") : handleLogin();
                }}
              >
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ marginRight: "20px" }}
                />
                Wishlist
              </li>
              <li
                onClick={() => {
                  isAuthenticated ? navigate("/cart") : handleLogin();
                }}
              >
                <FontAwesomeIcon
                  icon={faBagShopping}
                  style={{ marginRight: "24px" }}
                />
                Bag
              </li>
              <li
                onClick={() => {
                  isAuthenticated ? navigate("/Address") : handleLogin();
                }}
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{ marginRight: "23px", marginLeft: "3px" }}
                />
                Saved Addresses
              </li>

              {isAuthenticated ? (
                <>
                  <li onClick={() => navigate("/profile")}>
                    <FontAwesomeIcon
                      icon={faUserPen}
                      style={{ marginRight: "18px" }}
                    />
                    Edit Profile
                  </li>{" "}
                  <li onClick={() => handleLogout()}>
                    <FontAwesomeIcon
                      icon={faRightFromBracket}
                      style={{ marginRight: "22px" }}
                    />
                    Logout
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>

        <button
          className="nav-button"
          onClick={() => {
            isAuthenticated ? navigate("/cart") : handleLogin();
          }}
        >
          <RiShoppingBagLine style={{ fontSize: "20px" }} />
          <span className="button-text">Bag</span>
        </button>
        <button
          className="nav-button"
          onClick={() => {
            navigate("/BecomeASeller");
          }}
        >
          <BsShop style={{ fontSize: "18px" }} />
          <span className="button-text">Sell Now</span>
        </button>
      </div>
    </nav>
  );
};

export default HomeNavbar;
