import React, { useEffect, useState } from "react";
import axios from "axios";
import AdminHeader from "../Adminheader/Adminheader";
import "./Orders.css";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Tracker from "../../components/tracker/tracker";
import Modal from "react-bootstrap/Modal";

const AdminOrder = () => {
  const [data, setdata] = useState([]);
  const [ithinkdata, setithinkdata] = useState([]);
  const [deliverdata, setdelivereddata] = useState([]);
  const [cancelledData, setcancelledData] = useState([]);
  const [status, setStatus] = useState("Delivered");
  const navigate = useNavigate();
  const [lgShow, setLgShow] = useState(false);
  const [show, setshow] = useState({});

  const handleChange = (event) => {
    if (event.target.value !== "") {
      setStatus(event.target.value);
    }
  };

  const handle = (order) => {
    // console.log(order);
    setshow(order);
    setLgShow(!lgShow);
  };

  const fetchOrders = async () => {
    let url = process.env.REACT_APP_HOST + `order/getAllorders`;
    try {
      const response = await axios.get(url);
      // console.log(response.data);
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const fetchIthinkOrders = async () => {
    let url = process.env.REACT_APP_HOST + `order/getAllcompleteorders`;
    try {
      const response = await axios.get(url);
      //console.log(response.data);
      setithinkdata(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const fetchDeliveredOrders = async () => {
    let url = process.env.REACT_APP_HOST + `order/getAllDeliveredorders`;
    try {
      const response = await axios.get(url);
      //console.log("delivered",response.data);
      setdelivereddata(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const fetchcancelledOrders = async () => {
    let url = process.env.REACT_APP_HOST + `order/cancelledOrders`;
    try {
      const response = await axios.get(url);
      //console.log("delivered",response.data);
      setcancelledData(response.data);
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const UpdateOrder = async (id) => {
    let url = process.env.REACT_APP_HOST + `order/Updateorderstatus/${id}`;
    try {
      const response = await axios.patch(url, { status });
      setStatus("");
      fetchIthinkOrders();
      setLgShow(false);
    } catch (error) {
      console.error("Error Updating order:", error);
    }
  };

  const handlecompleteorder = (id) => {
    localStorage.setItem("completeorderid", id);
    navigate("/completeorder");
  };

  useEffect(() => {
    fetchOrders();
    fetchIthinkOrders();
    fetchDeliveredOrders();
    fetchcancelledOrders();
  }, []);
  return (
    <div>
      <AdminHeader />
      <Tabs
        defaultActiveKey="home"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="home" title="ORDERED">
          <div className="orders-contain">
            {data.map((order) => (
              <div key={order._id} className="order-card">
                <div className="order-info">
                  <h2>Order ID: {order._id}</h2>
                  <p>Status: {order.status}</p>
                  <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
                </div>
                <div className="address">
                  <div className="address-header">
                    <h3 className="address-title">
                      {order.shippingAddress.name}{" "}
                      {order.shippingAddress.mobileNumber}
                    </h3>
                  </div>
                  <p className="address-details">
                    {order.shippingAddress.address},{" "}
                    {order.shippingAddress.locality},
                    {order.shippingAddress.landmark},
                    {order.shippingAddress.city}, {order.shippingAddress.state}{" "}
                    -{order.shippingAddress.pincode}
                  </p>
                </div>
                <div className="products-container">
                  {order.products.map((product, index) => (
                    <div key={index} className="product-car">
                      <img
                        src={product.images[0]}
                        alt={product.title}
                        className="product-imag"
                      />
                      <div className="product-info">
                        <h3>{product.title}</h3>
                        <p>{product.description}</p>
                        <p>
                          Price: ${product.discountedPrice * product.quantity}
                          <span className="original-price">
                            ${product.price}
                          </span>
                        </p>
                        <p>Quantity: {product.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="status-selector">
                  <button
                    style={{ width: "300px", padding: "10px" }}
                    onClick={() => {
                      handlecompleteorder(order._id);
                    }}
                  >
                    Complete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </Tab>
        <Tab eventKey="profile" title="ITHINK">
          <div className="ord">
            <div className="orders-container">
              {ithinkdata.map((order) => (
                <div
                  key={order._id}
                  className="order-cardd"
                  onClick={() => handle(order)}
                >
                  <div className="products-containe">
                    {order.products.map((product, index) => (
                      <div key={index} className="product-cardd">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imaged"
                        />
                        <div className="product-info">
                          <h3 className="product-title">{product.title}</h3>
                          <p className="product-description">
                            {product.description}
                          </p>

                          <p>Quantity: {product.quantity}</p>
                        </div>
                        <p className="discounted-price">
                          ${product.discountedPrice * product.quantity}{" "}
                          {/* <span className="original-price">${product.price}</span> */}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab>
        <Tab eventKey="deliver" title="DELIVERED">
          <div className="ord">
            <div className="orders-container">
              {deliverdata.map((order) => (
                <div
                  key={order._id}
                  className="order-cardd"
                  onClick={() => handle(order)}
                >
                  <div className="products-containe">
                    {order.products.map((product, index) => (
                      <div key={index} className="product-cardd">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imaged"
                        />
                        <div className="product-info">
                          <h3 className="product-title">{product.title}</h3>
                          <p className="product-description">
                            {product.description}
                          </p>

                          <p>Quantity: {product.quantity}</p>
                        </div>
                        <p className="discounted-price">
                          ${product.discountedPrice * product.quantity}{" "}
                          {/* <span className="original-price">${product.price}</span> */}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab>
        <Tab eventKey="longer-tab" title="CANCELLED">
          <div className="ord">
            <div className="orders-container">
              {cancelledData.map((order) => (
                <div
                  key={order._id}
                  className="order-cardd"
                  onClick={() => handle(order)}
                >
                  <div className="products-containe">
                    {order.products.map((product, index) => (
                      <div key={index} className="product-cardd">
                        <img
                          src={product.images[0]}
                          alt={product.title}
                          className="product-imaged"
                        />
                        <div className="product-info">
                          <h3 className="product-title">{product.title}</h3>
                          <p className="product-description">
                            {product.description}
                          </p>
                          <p>Quantity: {product.quantity}</p>
                        </div>
                        <p className="discounted-price">
                          ${product.discountedPrice * product.quantity}{" "}
                          {/* <span className="original-price">${product.price}</span> */}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab>
        <Tab eventKey="contact" title="RETURNED">
          <div className="ord"></div>
        </Tab>
      </Tabs>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            ORDER AND SHIPPING DETAILS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show._id ? (
            <>
              <div className="order-info">
                <h2>Order ID: {show._id}</h2>
                <p>Date: {new Date(show.createdAt).toLocaleDateString()}</p>
              </div>
              <div>
                <div className="add">
                  <div className="addinne">
                    <h3 className="add-title">
                      {show.shippingAddress.name}{" "}
                      {show.shippingAddress.mobileNumber}
                    </h3>
                  </div>
                  <p className="add-addrss" style={{ margin: "0px" }}>
                    {show.shippingAddress.address},
                    {show.shippingAddress.locality},{show.landmark}
                    {show.shippingAddress.city} , {show.shippingAddress.state}-
                    {show.shippingAddress.pincode}
                  </p>
                </div>
              </div>
              <div className="products-containe">
                {show &&
                  show.products.map((product, index) => (
                    <div key={index} className="product-cardd">
                      <img
                        src={product.images[0]}
                        alt={product.title}
                        className="product-imaged"
                      />
                      <div className="product-info">
                        <h3 className="product-title">{product.title}</h3>
                        <p className="product-description">
                          {product.description}
                        </p>

                        <p>Quantity: {product.quantity}</p>
                      </div>
                      <p className="discounted-price">
                        ${product.discountedPrice * product.quantity}{" "}
                      </p>
                    </div>
                  ))}
                <Tracker status={show.status} waybill={show.waybill} />
                <div className="status-selector">
                  <button
                    onClick={() => UpdateOrder(show._id)}
                    style={{
                      width: "200px",
                      padding: "10px",
                      background: "black",
                      color: "white",
                    }}
                  >
                    DELIVERED
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminOrder;
